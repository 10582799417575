import { useEffect, useState } from "react";
import PasswordInput from "../Components/PasswordInput";
import { auth, database } from "../FirebaseConfig";
import { enqueueSnackbar } from "notistack";
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { equalTo, get, orderByChild, query, ref, set } from "firebase/database";
import "./ProfileSettings.css"
import TextField from '@mui/material/TextField';
import { userStore } from "../store";
import { isValidAndNotEmptyString, isValidObject } from "../utils";
import { validatePhoneNumber } from "../API";


const ProfileSettings = () => {
    const user = userStore((state) => state.user)
    const [newPassword, setNewPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [userName, setUserName] = useState("");
    const [phone, setPhone] = useState("");


    useEffect(() => {
        if(isValidObject(user)) {
            setUserName(user?.name || "")
            setPhone(user?.phone || "")
        }
    }, [user])

    const handle = async () => {
        try {
            const creds = EmailAuthProvider.credential(auth.currentUser.email, oldPassword)

            await reauthenticateWithCredential(auth.currentUser, creds)
            await updatePassword(auth.currentUser, newPassword)
            enqueueSnackbar("Şifre değiştirildi")
        } catch (err) {
            console.error("password change service error: ", err)
            enqueueSnackbar("Şifre değiştirilemedi!")
        }
    }
    const handleUserName = async () => {
        try {
            if (!isValidAndNotEmptyString(userName)) throw "Kullanıcı adı boş olamaz!"
            if (!isValidAndNotEmptyString(user?.uid)) throw "Kullanıcı bulunamadı"

            await set(ref(database, `users/${user.uid}/name`), userName)
            enqueueSnackbar("Kullanıcı adı güncellendi")
        } catch (err) {
            console.error("Kullanıcı adı güncellenemedi: ", err)
            enqueueSnackbar(err)
        }
    }
    const handlePhoneNumber = async () => {
        try {
            if (!validatePhoneNumber(phone)) throw "Telefon numbarası 0 ile başlamamalı ve 10 haneli olmalı"
            if (!isValidAndNotEmptyString(user?.uid)) throw "Kullanıcı bulunamadı"

            await set(ref(database, `users/${user.uid}/phone`), phone)
            enqueueSnackbar("Telefon numarası güncellendi")
        } catch (err) {
            console.error("Telefon numarası güncellenemedi: ", err)
            enqueueSnackbar(err)
        }
    }

    useEffect(() => {
        console.log(phone)
    }, [phone])
    return (
        <div className={"ProfileSettingsWrapper"}>
            <div className={"ProfileSettingsRow"}>
                <div className="inputsWrapper">
                    <PasswordInput
                        val={oldPassword}
                        set={setOldPassword}
                        label={"Eski Şifre"}
                    />
                    <PasswordInput
                        val={newPassword}
                        set={setNewPassword}
                        label={"Yeni Şifre"}
                    />
                </div>
                <button onClick={() => handle()} className="drawerButton">
                    Güncelle
                </button>
            </div>
            <div className={"ProfileSettingsRow"}>
                <div className="inputsWrapper">
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        value={userName}
                        type="text"
                        onChange={(e) => setUserName(e.target.value)}
                        label={"Kullanıcı Adı"}
                    />
                </div>

                <button onClick={() => handleUserName()} className="drawerButton">
                    Güncelle
                </button>
            </div>
            <div className={"ProfileSettingsRow"}>
                <div className="inputsWrapper">
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        value={phone}
                        type="number"
                        onChange={(e) => setPhone(e.target.value)}
                        label={"Tel No"}
                    />
                </div>

                <button onClick={() => handlePhoneNumber()} className="drawerButton">
                    Güncelle
                </button>
            </div>
        </div>
    )
}

export default ProfileSettings;