import React, { useEffect, useState } from "react"
import Pagination from '@mui/material/Pagination'
import Box from '@mui/material/Box';
import { isValidArrayAndHasLength } from "../../utils";
import { branchesStore, categoriesStore, pendingStore, productsStore, selectedBranchStore, usersStore } from "../../store";
import UsersCard from "./UserCard";
import UsersDrawer from "./UsersDrawer";
import { onValue, query, ref } from "firebase/database";
import { database } from "../../FirebaseConfig";

const UsersComponent = () => {

    const branches = branchesStore((state) => state.branches)
    const setBranches = branchesStore((state) => state.setBranches)

    const categories = categoriesStore((state) => state.categories)
    const selectedBranch = selectedBranchStore((state) => state.selectedBranch)


    const users = usersStore((state) => state.users)
    const setUsers = usersStore((state) => state.setUsers)


    useEffect(() => {
        const usersRef = query(
            ref(database, 'users/')
        );
        onValue(usersRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = Object.entries(snapshot.val());
                setUsers(data);
            } else {
                console.error("no users found in database")
                setUsers([]);
            }
        });
    }, [])

    const [page, setPage] = useState(1)

    const setPending = pendingStore((state) => state.setPending)
    const pending = pendingStore((state) => state.pending)


    const setCategories = categoriesStore(state => state.setCategories)
    const products = productsStore(state => state.products)
    const setProducts = productsStore(state => state.setProducts)

    const style = {
        boxStyle: {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
            minHeight: 350,

        },
        boxStyleCol: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
        }
    }
    return (
        <Box style={style.boxStyleCol}>

            <div className={"tableHead"}>
                <h2 className="section-title">Kullanıcılar</h2>
                <UsersDrawer />
            </div>

            <Box style={style.boxStyle}>
                 {
                    isValidArrayAndHasLength(users) ?
                        users?.filter((item, i) => i >= 6 * (page - 1) && i < 6 * (page) && item[1]?.isAdmin === true).map((item) => <UsersCard key={item[0]} data={item} />)
                        : null
                }
                {
                    isValidArrayAndHasLength(users) ?
                        users?.filter((item, i) => i >= 6 * (page - 1) && i < 6 * (page) && item[1]?.isAdmin !== true).map((item) => <UsersCard key={item[0]} data={item} />)
                        : null
                }
            </Box>
            {
                isValidArrayAndHasLength(users) ?
                    <Pagination className="pagination" count={Math.ceil((users.length) / 6)} onChange={(e, val) => setPage(val)} variant="outlined" />
                    : null
            }
        </Box>
    )
}

export default UsersComponent;