import "./SearchPage.css";
import {
    useNavigate,
} from 'react-router-dom';
import { t } from 'i18next';
import { useEffect, useState } from "react";
import { languageStore, productsStore, selectedBranchStore, selectedProductsStore } from "../store.js"
import Tooltip from '@mui/material/Tooltip';
import { tagsArrWithSvg } from "./Components/utils";
import { isValidAndNotEmptyString, isValidArrayAndHasLength, isValidObject } from "../utils.js";
import { get, ref } from "firebase/database";
import { database } from "../FirebaseConfig.js";





const SearchPage = () => {

    const selectedBranch = selectedBranchStore(state => state.selectedBranch)
    const language = languageStore(state => state.language)

    const goBackSvg = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="text-primary h-6 w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"></path></svg>
    const selectedProducts = selectedProductsStore(state => state.selectedProducts)
    const products = productsStore(state => state.products)

    const [pending, setPendding] = useState(false)
    const navigate = useNavigate();

    const [value, setValue] = useState(localStorage.getItem("searchPage-value") ? JSON.parse(localStorage.getItem("searchPage-value")) : "")

    const [result, setResult] = useState([])


    function levenshteinMesafesi(kelime1, kelime2) {
        const matrix = [];

        for (let i = 0; i <= kelime1.length; i++) {
            matrix[i] = [i];
        }

        for (let j = 0; j <= kelime2.length; j++) {
            matrix[0][j] = j;
        }

        for (let i = 1; i <= kelime1.length; i++) {
            for (let j = 1; j <= kelime2.length; j++) {
                const maliyet = kelime1[i - 1] === kelime2[j - 1] ? 0 : 1;
                matrix[i][j] = Math.min(
                    matrix[i - 1][j] + 1,
                    matrix[i][j - 1] + 1,
                    matrix[i - 1][j - 1] + maliyet
                );
            }
        }

        return matrix[kelime1.length][kelime2.length];
    }


    const searchSvg = <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-secondary h-5 w-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path></svg>
    const closeSvg = <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true" class="text-secondary h-5 w-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg>



    const filterData = async () => {
        setResult([])
        setPendding(true)
        let filteredArr = products?.filter((item) => item[1]?.name?.toLocaleLowerCase("tr")?.includes(value?.toLocaleLowerCase("tr")) || item[1]?.nameEN?.toLocaleLowerCase("en").includes(value?.toLocaleLowerCase("en")))

        let formatedArr = await Promise.all(filteredArr?.map(async (item) => {
            let branch = await get(ref(database, `branches/${item[1]?.branch}/name`));
            let category = await get(ref(database, `categories/${item[1]?.category}/name`));
            console.log("branch", branch.val());
            return [
                item[0], { ...item[1], branchName: branch.exists() ? branch.val() : "-", categoryName: category.exists() ? category.val() : "-" }
            ];
        }));
        setPendding(false)

        setResult(formatedArr)
    }

    useEffect(() => {
        if (isValidArrayAndHasLength(selectedBranch)) {
            filterData()
            localStorage.setItem("searchPage-value", JSON.stringify(value))
        }
    }, [value, selectedBranch])

    useEffect(() => {
        console.log("result", result)
    }, [result])


    useEffect(() => {
        let a = document.querySelectorAll(".productRow")
        if (a?.length > 0) {
            console.log(a.length)
            a.forEach((el, i) => {
                el.style.animation = "none"
                el.style.animation = `product-row-start .6s ${i * 90}ms cubic-bezier(0.4, 0, 0.2, 1) forwards`
            })
        }
    }, [result])

    const formatter = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2
    });
    return (
        <div className={"SearchPageWrapper"}>
            <header>
                <button className="goBackButton" onClick={() => navigate(-1)}>
                    {goBackSvg}
                </button>
                <div className="searchInputWrapper">
                    {searchSvg}
                    <input
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                    {
                        isValidAndNotEmptyString(value)
                            ? <button onClick={() => setValue("")}>{closeSvg}</button>
                            : null
                    }
                </div>
            </header>
            <body>
                {
                    isValidAndNotEmptyString(value)
                        ? <h1>'{value}' {t("for")} {result.length} {t("result")}</h1>
                        : null
                }

                {
                    !pending ? result.map((item) => {
                        function findLargestTwoNumbers(arr) {
                            // Diziyi küçükten büyüğe doğru sırala
                            arr.sort(function (a, b) {
                                return b - a; // Büyükten küçüğe sıralama
                            });

                            // En büyük iki sayıyı al
                            var largestTwoNumbers = [arr[0], arr[arr.length - 1]];

                            return largestTwoNumbers;
                        }
                        let priceArr = []
                        if (isValidArrayAndHasLength(item[1]?.options) && item[1]?.options.length > 1) {
                            priceArr = findLargestTwoNumbers([...item[1].options.map((item) => item?.price), item[1]?.price])
                        }

                        return <div className="beardAndRowWrapper">
                            <p>{item[1]?.branchName} / {item[1]?.categoryName}</p>
                            <a href={`menu/${item[0]}`} className={"productRow"}>
                                <div>
                                    <h4>{language === "tr" ? item[1].name : item[1].nameEN}</h4>
                                    <p>{language === "tr" ? item[1].description : item[1].descriptionEN}</p>
                                    <span>
                                        {
                                            isValidArrayAndHasLength(item[1]?.options) && item[1]?.options?.length > 1 ?
                                                formatter.format(priceArr[1]) + " - " + formatter.format(priceArr[0])
                                                : formatter.format(item[1]?.price)
                                        }
                                    </span>
                                    <div className={"tagsWrapper"}>
                                        {
                                            tagsArrWithSvg.filter((a) => item[1].tags?.map(item => item.toLocaleUpperCase("tr")).includes(a.name.toLocaleUpperCase("tr")))?.map((item) => {
                                                return <Tooltip title={item.name} placement="top">
                                                    {item.svg}
                                                </Tooltip>
                                            })
                                        }
                                    </div>
                                </div>
                                <div>
                                    <img src={item[1].logo} />
                                </div>
                            </a>
                        </div>
                    }) : "arıyor"
                }
            </body>
            <footer>
                <button onClick={() => filterData()}>{t("search")}</button>
            </footer>
        </div>
    )
}

export default SearchPage;