import { t } from "i18next";
import { tagsArrWithSvg } from "./utils";
import { useEffect, useState } from "react";
import { filterTagsStore, languageStore, modalStore } from "../../store";

const FilterTags = () => {

    const filterTags = filterTagsStore(state => state.filterTags)
    const setFilterTags = filterTagsStore(state => state.setFilterTags)
    const setModal = modalStore(state => state.setModal)
    const language = languageStore(state => state.language)

    



    const handleSelect = (name, nameEN) => {
        if (filterTags.includes(name)) {
            setFilterTags(filterTags.filter((a) => a !== name && a !== nameEN ))
        } else {
            setFilterTags([...filterTags, name, nameEN])
        }
    }


    const handleSet = () => {
        setModal({status: false})
    }


    return (
        <div className={"FilterTagsWrapper"}>
            <h2>{t("ingredients")}</h2>

            <div>
                {
                    tagsArrWithSvg.map((item) => {
                        return <button onClick={() => handleSelect(item.name, item.nameEN)}><input checked={filterTags.includes(item.name) || filterTags.includes(item.nameEN)} type="checkbox" />{language === "tr" ? item.name : item.nameEN}</button>
                    })
                }
            </div>
            <div className={"footer"}>
                <button onClick={() => handleSet()} >{t("filter")}</button>
            </div>
        </div>
    )
}

export default FilterTags;