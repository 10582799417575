import { dialogStore } from "./store";

export const showConfirmationPopup = (message) => {
    return new Promise((resolve) => {
        dialogStore.getState().setDialog({ status: true, title: "Dikkat", message: message, resolve: resolve })
    });
};

export const stringToColour = (str) => {
    let hash = 0;
    str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let colour = '#'
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += value.toString(16).padStart(2, '0')
    }
    return colour
}

export function validatePhoneNumber(phoneNumber) {
    // Belirtilen regex deseni
    var regexPattern = /^(?!0)\d{10}$/;

    // Telefon numarasını doğrula
    if (regexPattern.test(phoneNumber)) {
        return true;
    } else {
        return false;
    }
}



const grid = 10;

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);

    console.warn("result", startIndex, endIndex)
    const [removed] = result.splice(startIndex, 1);
    console.warn("removed", removed, result)
    result.splice(endIndex, 0, removed);

    return result;
};

export const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: `0 0 ${grid}px 0`,
    width: "100%",
    padding: 0,
    // change background colour if dragging

    // styles we need to apply on draggables
    ...draggableStyle
});

export const getListStyle = isDraggingOver => ({
    width: "100%",
    minHeight: 350,
    background: isDraggingOver ? "transparent" : "transparent",
});