import { t } from "i18next";
import { useEffect, useState } from "react";
import { selectedBranchStore } from "../../store";
import { isValidArrayAndHasLength, isValidObject } from "../../utils";

const Info = () => {
    const selectedBranch = selectedBranchStore(state => state.selectedBranch)

    const [brandName, setBrandName] = useState("")
    const [brandAddress, setBrandAddress] = useState("")
    const [brandTellNo, setBrandTellNo] = useState("")


    useEffect(() => {
        if (isValidObject(selectedBranch) && isValidArrayAndHasLength(Object.entries(selectedBranch))) {
            setBrandName(Object.entries(selectedBranch)[0][1].name)
            setBrandAddress(Object.entries(selectedBranch)[0][1].address)
            setBrandTellNo(Object.entries(selectedBranch)[0][1].tellNo)
        }
    }, [selectedBranch])



    return (
        <div className={"infoWrapper"}>
            <h2>{brandName.toLocaleUpperCase("tr")}</h2>

            <div className={"titleAndText"}>
                <h3>{t("address")}</h3>
                <p>{brandAddress}</p>
            </div>
            <div className={"titleAndText"}>
                <h3>{t("Need Help?")}</h3>
                <div className="callUsOnRow">
                    <p>{t("call-us-on")}</p>
                    <a href={`tel:+90 ${brandTellNo}`}>+90 {brandTellNo}</a>
                </div>
            </div>

        </div>
    )
}

export default Info;