import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { signOut } from 'firebase/auth';
import { auth } from './FirebaseConfig';
import { adminLeftDrawerStore, feedBackStore, userStore } from './store';
import { useNavigate } from "react-router-dom";
import TerminalRoundedIcon from '@mui/icons-material/TerminalRounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import { isValidAndNotEmptyString, isValidArrayAndHasLength } from './utils';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import "./Header.css"
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';


export default function Header() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const leftDrawer = adminLeftDrawerStore((state) => state.leftDrawer)
    const setLeftDrawer = adminLeftDrawerStore((state) => state.setLeftDrawer)


    const user = userStore((state) => state.user)

    const feedBack = feedBackStore((state) => state.feedBack)
    const navigate = useNavigate()

    const handleSignOut = async () => {
        try {
            let ress = await signOut(auth)
            console.log("sign out success: ", ress)
        } catch (err) {
            console.error("sign out error: ", err)
        }
    }



    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',

            }}
            style={{
                zIndex: 9999,
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => handleSignOut()}>
                <LogoutRoundedIcon />
                Çıkış yap
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';

    const userChipStyle = {
        "&": {
            border: "1px solid #1b1b1b !important",
        },
        "& > div": {
            backgroundColor: "#1b1b1b !important",
            color: "#fff !important",
            marginLeft: "3px !important",
        },
        "& > span": {
            textTransform: "capitalize",
        }
    }
    return (
        <div className='AdminHeaderWrapper'>
            <div className='searchInputWrapper'>
                <SearchRoundedIcon />
                <input type='text' placeholder='Arama Yap' />
            </div>

            <div className="headerButtonsWrapper">
                <Tooltip title="Mesajlar">
                    <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                        <Badge onClick={() => navigate("messages")} badgeContent={isValidArrayAndHasLength(feedBack) ? feedBack?.filter(item => item[1]?.readed !== true)?.length : 0} color="error">
                            <MailIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>

                <Tooltip title="Menu Paneli">
                    <IconButton onClick={() => navigate("")} size="large" aria-label="show 4 new mails" color="inherit">
                        <TerminalRoundedIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Hesap Ayarları">
                    <IconButton onClick={() => navigate("profile-settings")} size="large" aria-label="show 4 new mails" color="inherit">
                        <ManageAccountsIcon />
                    </IconButton>
                </Tooltip>

                {
                    isValidAndNotEmptyString(user?.name) ?
                        <Chip sx={userChipStyle} avatar={<Avatar>{user?.name[0]?.toLocaleUpperCase("tr")}</Avatar>} label={user?.name} /> :
                        <Chip avatar={<Avatar>K</Avatar>} label="Kullanıcı" />
                }


                <Tooltip title="Çıkış Yap">
                    <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={() => handleSignOut()}
                        color="inherit"
                    >
                        <ExitToAppRoundedIcon />
                    </IconButton>
                </Tooltip>


            </div>
        </div>
    );
}