import { signOut } from "firebase/auth"
import { auth, database } from "./FirebaseConfig"
import CustomTable from "./CustomTable"
import CustomDrawer from "./CustomDrawer.js"
import { adminLeftDrawerStore, branchesStore, pendingStore, toastStore } from "./store.js"
import Button from '@mui/material/Button';
import CurrentBranchesDialog from "./CurrentBranchesDialog.js"
import { onValue, push, ref } from "firebase/database"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect } from "react"
import Header from "./Header.js"
import CustomCard from "./CustomCard.js"
import Pagination from '@mui/material/Pagination';
import BrachesComponent from "./BrachesComponent.js"
import CategoriesComponent from "./CategoriesComponent.js"
import ProductComponent from "./ProductComponent.js"
import CustomDialog from "./CustomDialog.js"
import Snackbar from '@mui/material/Snackbar';
import Divider from '@mui/material/Divider';
import UsersComponent from "./AdminSide/ForUsersComponent/UsersComponent.js"

const Dasboard = () => {

    const setPending = pendingStore((state) => state.setPending)
    const pending = pendingStore((state) => state.pending)
    const toast = toastStore((state) => state.toast)
    const setToast = toastStore((state) => state.setToast)

    const leftDrawer = adminLeftDrawerStore((state) => state.leftDrawer)



    const branches = branchesStore((state) => state.branches)
    const setBranches = branchesStore((state) => state.setBranches)



    useEffect(() => {
        console.warn("branches: ", branches)
    }, [branches])

    const arr = [
        <BrachesComponent />,
        <CategoriesComponent />,
        <ProductComponent />,
        <UsersComponent />,
    ]

    return (
        <div className="bodyWrapper">
            {arr[leftDrawer?.which] || arr[0]}
        </div>
    );
}

export default Dasboard;
