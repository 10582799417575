import { useParams } from 'react-router-dom';
import ProductPage from './ProductPage';
import { Outlet } from "react-router-dom";
import Header from './Components/Header';
import "./MenuTemplate.css"
import Modal from './Components/Modal';
import { branchesStore, categoriesStore, modalStore, productsStore, selectedBranchStore, selectedCategoryStore, sideMenuStore } from '../store';
import { isValidAndNotEmptyString, isValidArrayAndHasLength, isValidObject } from '../utils';
import { useEffect, useState } from 'react';
import { equalTo, onValue, orderByChild, orderByKey, orderByValue, query, ref } from 'firebase/database';
import { database } from '../FirebaseConfig';
import SideMenu from './SideMenu';
import { Helmet } from "react-helmet";

const MenuTemplate = () => {
    let { branchName } = useParams()
    const modal = modalStore(state => state.modal)
    const selectedBranch = selectedBranchStore(state => state.selectedBranch)
    const setSelectedBranch = selectedBranchStore(state => state.setSelectedBranch)
    const setCategories = categoriesStore(state => state.setCategories)
    const categories = categoriesStore(state => state.categories)
    const setProducts = productsStore(state => state.setProducts)
    const selectedCategory = selectedCategoryStore(state => state.selectedCategory)
    
    const params = useParams()
    const [logo, setLogo] = useState("")
    useEffect(() => {
        if (isValidAndNotEmptyString(params.branchName)) {
            const branchRef = query(
                ref(database, 'branches/'),
                orderByChild("name"),
                equalTo(params.branchName),
            );
            try {
                onValue(branchRef, (snapshot) => {
                    if (snapshot.exists()) {
                        const data = Object.entries(snapshot.val())[0];
                        setSelectedBranch(data);
                        setLogo(data[1]?.logo)
                    } else {
                        console.error("no data")
                        setSelectedBranch([]);
                    }
                });
            } catch (err) {
                console.error("err", err)
            }
        }

    }, [])




    return (
        <div className={"MenuTemplateWrapper"}>
            <Helmet>
                <title>{params.branchName}</title>
                <link rel="icon" type="image/png" href={logo} sizes="16x16" />
            </Helmet>
            <div className={"MenuTemplateLeftWrapper"}>
                {
                    !window.location.pathname.includes("/search") ?
                        <Header /> :
                        null
                }
                <div className={"outletWrapper"}>
                    <Outlet />
                </div>
            </div>
            <div className='MenuTemplateRightWrapper'></div>
            <Modal />
            <SideMenu />
        </div>

    )
}

export default MenuTemplate;