import { useEffect, useState } from "react";
import "./ImagePicker.css"
import { isValidAndNotEmptyString } from "./utils";
import axios from 'axios';





const ImagePicker = (props) => {

    const { val, set, customStyle, defaultValue, setDefaultValue } = props;
    // console.log("defaultValue", defaultValue)
    const [base64, setBase64] = useState(isValidAndNotEmptyString(defaultValue) ? defaultValue : "")
    const handleImageInput = async (e) => {
        set(e.target.files[0])
        // console.warn("önce: ", e.target.files[0])
        // let base64 = await toBase64(e.target.files[0])
        // let resized64 = await resizeBase64Img(base64, 200, 200)
        // let file = await urltoFile(resized64, "fileName.jpg", "")
        // console.warn("sonra: ", file)

        if (typeof e.target.files[0] === "object") {
            toBase64(e.target.files[0]).then((data) => setBase64(data))
        }
    }
    useEffect(() => {
        if (isValidAndNotEmptyString(defaultValue)) {
            setBase64(defaultValue)
        } 
    }, [defaultValue])


    function resizeBase64Img(base64, newWidth, newHeight) {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement("canvas");
            canvas.width = newWidth;
            canvas.height = newHeight;
            let context = canvas.getContext("2d");
            let img = document.createElement("img");
            img.src = base64;
            img.onload = function () {
                context.scale(newWidth / img.width, newHeight / img.height);
                console.warn("scale", newWidth / img.width, newHeight / img.height)
                context.drawImage(img, 0, 0);
                resolve(canvas.toDataURL());
            }
        });
    }


    function urltoFile(url, filename, mimeType) {
        if (url.startsWith('data:')) {
            var arr = url.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[arr.length - 1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            var file = new File([u8arr], filename, { type: mimeType });
            return Promise.resolve(file);
        }
        return fetch(url)
            .then(res => res.arrayBuffer())
            .then(buf => new File([buf], filename, { type: mimeType }));
    }




    const toBase64 = async (file) => {
        if (!file) return

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    console.log("defaultValue", defaultValue)
    const addSvg = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
    const removeSvg = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>

    return (
        <div
            className={val ? "imagePickerWrapper selected" : "imagePickerWrapper"}
            style={customStyle ? customStyle : null}
        >
            {
                val || isValidAndNotEmptyString(base64)
                    ? <img className="previewImage" src={base64} />
                    : null
            }
            {
                val || isValidAndNotEmptyString(base64)
                    ? <button className="deleteButton" onClick={() => {
                        set(null)
                        setDefaultValue("")
                        setBase64("")
                    }}>{removeSvg}</button>
                    : addSvg
            }

            <input onChange={(e) => handleImageInput(e)} type="file" />
        </div>
    )
}


export default ImagePicker;