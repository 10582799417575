import * as React from 'react';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { auth, database, storage } from '../../FirebaseConfig';
import { branchesDrawerStore, branchesStore, dialogStore, pendingStore, selectedBranchStore, usersDrawerStore } from '../../store';
import { isValidAndNotEmptyString, isValidArrayAndHasLength } from '../../utils';
import "../../BranchCard.css"
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ref, remove } from 'firebase/database';
import { useNavigate } from "react-router-dom"
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Tooltip from '@mui/material/Tooltip';
import AvatarGroup from '@mui/material/AvatarGroup';

export default function UsersCard(props) {
    const { data } = props;

    const navigate = useNavigate()

    const setDialog = dialogStore((state) => state.setDialog)

    const setPending = pendingStore((state) => state.setPending)

    const selectedBranch = selectedBranchStore((state) => state.selectedBranch)
    const setSelectedBranch = selectedBranchStore((state) => state.setSelectedBranch)


    const branches = branchesStore((state) => state.branches)


    const isUsersDrawerOpen = usersDrawerStore((state) => state.isUsersDrawerOpen)
    const setIsUsersDrawerOpen = usersDrawerStore((state) => state.setIsUsersDrawerOpen)

    const [expanded, setExpanded] = React.useState(false);
    const [url, setUrl] = React.useState("");
    const [imageError, setImageError] = React.useState(false);


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const aylar = [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık"
    ];

    let date = new Date(data[1].createdAt)

    React.useEffect(() => {
    }, [imageError])


    const showConfirmationPopup = () => {
        return new Promise((resolve) => {
            setDialog({ status: true, title: "Dikkat", message: `"${data[1].email}" hesabını silmek üzeresiniz.`, resolve: resolve })
        });
    };

    const handleDelete = async () => {
        const userConfirmed = await showConfirmationPopup();

        if (userConfirmed) {
            setPending(true)
            console.error("silindi", data[0])
            try {
                if (isValidArrayAndHasLength(data[1]?.ownedBranches)) {
                    data[1]?.ownedBranches?.forEach(async (id) => {
                        await remove(ref(database, `branches/${id}/owner`))
                    })
                }
                await remove(ref(database, `users/${data[0]}/`))
            } catch (err) {
                console.error("handleDeleteBranch error: ", err)
            }
            setPending(false)
        }
    }


    return (
        <div className={"BranchCardWrapper"}>
            <Card
                onClick={() => setSelectedBranch(data)}
                className={Array.isArray(selectedBranch) && selectedBranch[0] === data[0] ? "cardRow active" : "cardRow"}
                style={{
                    width: "100%",
                    height: 50,
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 10,

                }}
                sx={[
                    {
                        '&:hover': {
                            transition: ".2s",
                            backgroundColor: "#1976d240"
                        },
                    },

                ]}>

                <div className={"row-first-col"}>

                    <div className='titleAndDateWrapper'>

                        <h2>{data[1].email}</h2>
                        <h3>{aylar[date.getMonth()]} {date.getDate()}, {date.getFullYear()}</h3>
                    </div>
                </div>
                {/* {
                        data[1]?.isAdmin ?
                            <AdminPanelSettingsIcon />
                            : null
                    } */}
                <div className={"row-second-col"}>
                    <AvatarGroup max={4}>
                        {
                            data[1]?.isAdmin === true && isValidArrayAndHasLength(branches) ?  branches?.map((item) => {
                                return <Tooltip arrow title={item[1]?.name} placement="top-start">
                                    <Avatar className='avatarWrapper' sx={{ "& > img": { objectFit: "cover" } }} src={item[1]?.logo} />
                                </Tooltip>
                            })
                                : isValidArrayAndHasLength(branches) ? branches?.filter((item) => data[1]?.ownedBranches?.includes(item[0]))?.map((item) => {
                                    return <Tooltip arrow title={item[1]?.name} placement="top-start">
                                        <Avatar className='avatarWrapper' sx={{ "& > img": { objectFit: "cover" } }} src={item[1]?.logo} />
                                    </Tooltip>
                                }) : null
                        }
                    </AvatarGroup>
                </div>
                <div className='buttonsWrapper'>
                    <IconButton disabled={data[1]?.isAdmin} onClick={() => setIsUsersDrawerOpen({ status: true, type: "update", data: data })} aria-label="delete">
                        <ModeEditIcon />
                    </IconButton>
                    <IconButton disabled={data[1]?.isAdmin} onClick={() => handleDelete()} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </div>

            </Card>
        </div>
    );
}