import { create } from 'zustand'
import { isValidArrayAndHasLength, isValidObject } from './utils';

const pendingStore = create((set) => ({
  pending: false,
  setPending: (newData) => set({ pending: newData }),
}))

const branchesStore = create((set) => ({
  branches: [],
  setBranches: (newData) => set({ branches: newData }),
}))
const categoriesStore = create((set) => ({
  categories: [],
  setCategories: (newData) => set({ categories: newData }),
}))
const productsStore = create((set) => ({
  products: [],
  setProducts: (newData) => set({ products: newData }),
}))

const usersStore = create((set) => ({
  users: [],
  setUsers: (newData) => set({ users: newData }),
}))

const userStore = create((set) => ({
  user: [],
  setUser: (newData) => set({ user: newData }),
}))

const selectedBranchStore = create((set) => ({
  selectedBranch: branchesStore.getState().branches[0] || null,
  setSelectedBranch: (newData) => set({ selectedBranch: newData }),
}))


branchesStore.subscribe(
  (a) => {
    // console.log("store", selectedBranchStore.getState().selectedBranch, a.branches)
    let branches = a.branches
    if (selectedBranchStore.getState().selectedBranch) {
      let selectedBranch = selectedBranchStore.getState().selectedBranch
      if (isValidArrayAndHasLength(branches)) {
        let isThere = branches?.some(item => item[0] === selectedBranch[0])
        if (isThere) {
          let updated = branches.find(item => item[0] === selectedBranch[0])
          selectedBranchStore.setState({ selectedBranch: updated });
        }
      }
    } else {
      selectedBranchStore.setState({ selectedBranch: branches[0] || null });
    }
  },
  (state) => state.branches
);


const selectedCategoryStore = create((set) => ({
  selectedCategory: null,
  setSelectedCategory: (newData) => set({ selectedCategory: newData }),
}))
categoriesStore.subscribe(
  (a) => {
    let categories = a.categories

    if (!isValidArrayAndHasLength(categories)) return

    if (selectedCategoryStore.getState().selectedCategory) {
      let selectedCategory = selectedCategoryStore.getState().selectedCategory

      let isThere = categories.some(item => item[0] === selectedCategory[0])
      if (isThere) {
        let updated = categories.find(item => item[0] === selectedCategory[0])

        selectedCategoryStore.setState({ selectedCategory: updated });
      }
    } else {
      console.log("burdan geçti")
      selectedCategoryStore.setState({ selectedCategory: categories[0] || null });
    }
  },
  (state) => state.branches
);

const selectedProductStore = create((set) => ({
  selectedProduct: null,
  setSelectedProduct: (newData) => set({ selectedProduct: newData }),
}))

const toastStore = create((set) => ({
  toast: {
    open: true,
    message: "",
    key: new Date().getTime(),
  },
  setToast: (newData) => set({ toast: { ...newData, key: new Date().getTime() } }),
}))

const dialogStore = create((set) => ({
  dialog: { status: false, title: "", message: "", resolve: null },
  setDialog: (newData) => set({ dialog: newData }),
  updateDialog: (newData) => set((prev) => ({ dialog: { ...prev.dialog, ...newData } })),
}))

const branchesDrawerStore = create((set) => ({
  isBranchesDrawerOpen: false,
  setIsBranchesDrawerOpen: (newData) => set({ isBranchesDrawerOpen: newData }),
}))
const categoryDrawerStore = create((set) => ({
  isCategoryDrawerOpen: { status: false, type: "new" },
  setIsCategoryDrawerOpen: (newData) => set({ isCategoryDrawerOpen: newData }),
}))
const productDrawerStore = create((set) => ({
  isProductDrawerOpen: {status: false, data: ["", {}]},
  setIsProductDrawerOpen: (newData) => set({ isProductDrawerOpen: newData }),
}))
const usersDrawerStore = create((set) => ({
  isUsersDrawerOpen: false,
  setIsUsersDrawerOpen: (newData) => set({ isUsersDrawerOpen: newData }),
}))

// menu side ------------------------------------------------------------------------

const modalStore = create((set) => ({
  modal: { status: false, type: "" },
  setModal: (newData) => set({ modal: newData }),
}))

const languageStore = create((set) => ({
  language: localStorage.getItem("language") || "tr",
  setLanguage: (newData) => set({ language: newData }),
}))
languageStore.subscribe((state) => {
  localStorage.setItem("language", state.language)
})

const filterTagsStore = create((set) => ({
  filterTags: [],
  setFilterTags: (newData) => set({ filterTags: newData }),
}))


const sideMenuStore = create((set) => ({
  sideMenu: false,
  setSideMenu: (newData) => set({ sideMenu: newData }),
}))



const feedBackStore = create((set) => ({
  feedBack: false,
  setFeedBack: (newData) => set({ feedBack: newData }),
}))

const adminLeftDrawerStore = create((set) => ({
  leftDrawer: { status: !(window?.innerWidth <= 1000), which: 0 },
  setLeftDrawer: (newData) => set({ leftDrawer: newData }),
}))


const selectedCategoriesStore = create((set) => ({
  selectedCategories: [],
  setSelectedCategories: (newData) => set({ selectedCategories: newData }),
}))

selectedCategoriesStore.subscribe(
  (a) => {
    // console.log("store", selectedBranchStore.getState().selectedBranch, a.branches)
    let selectedCategories = a.selectedCategories
    let selectedBranch = selectedBranchStore.getState().selectedBranch
    let setSelectedCategory = selectedCategoryStore.getState().setSelectedCategory
    
    if(isValidArrayAndHasLength(selectedCategories)) {

      if(selectedCategories[1]?.branch !== selectedBranch[0]) {
        setSelectedCategory(selectedCategories[0])
      }
    }else {
      setSelectedCategory(selectedCategories[0])
    }
  },
  (state) => state.branches
);

const selectedProductsStore = create((set) => ({
  selectedProducts: [],
  setSelectedProducts: (newData) => set({ selectedProducts: newData }),
}))

export {
  pendingStore, branchesStore, categoriesStore,
  productsStore, usersStore, userStore, selectedBranchStore, selectedCategoryStore,
  selectedProductStore, dialogStore, branchesDrawerStore, categoryDrawerStore,
  productDrawerStore, usersDrawerStore, modalStore, languageStore, filterTagsStore, sideMenuStore,
  toastStore, feedBackStore, adminLeftDrawerStore, selectedCategoriesStore,
  selectedProductsStore
}