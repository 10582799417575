import "./CustomFilterButton.css"

const CustomFilterButton = (props) => {
    const {set, val, labels, style} = props



    return (
        <div style={style} className={"filterButtonsWrapper"}>
            <button className={val ? "filterButton" : "filterButton active"} variant={val ? "text" : "outlined"} onClick={() => set(false)} sx={{ width: "50%" }}>{labels[0]}</button>
            <button className={!val ? "filterButton" : "filterButton active"} variant={val ? "outlined" : "text"} onClick={() => set(true)} sx={{ width: "50%" }}>{labels[1]}</button>
        </div>
    )
}

export default CustomFilterButton