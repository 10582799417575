import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { adminLeftDrawerStore, userStore } from './store';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ClassIcon from '@mui/icons-material/Class';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import "./LeftSideDrawer.css";
import { useNavigate, useLocation } from "react-router-dom"
import Groups2Icon from '@mui/icons-material/Groups2';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';


const drawerWidth = 240;


const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export default function LeftSideDrawer() {
    const theme = useTheme();
    const leftDrawer = adminLeftDrawerStore((state) => state.leftDrawer)
    const setLeftDrawer = adminLeftDrawerStore((state) => state.setLeftDrawer)
    const user = userStore((state) => state.user)


    const navigate = useNavigate()
    const location = useLocation()
    const handleChange = (which) => {
        if (location.pathname !== "/admin") {
            navigate("/admin")
        }
        setLeftDrawer({ status: leftDrawer?.status, which: which })
    }

    React.useEffect(() => {
        if (location.pathname !== "/admin") {
            setLeftDrawer({ status: leftDrawer?.status, which: null })
        }
    }, [location.pathname])
    return (
        <div className={leftDrawer.status ? 'LeftSideDrawer open' : 'LeftSideDrawer'}
            anchor={ !(window?.innerWidth <= 1000) ? "left" : "bottom"}
            open={leftDrawer?.status}
            variant= {!(window?.innerWidth <= 1000) ? "permanent" : null}
        >
            <div className='fade' onClick ={() => setLeftDrawer({ status: false, which: leftDrawer?.which })}></div>

            <DrawerHeader className='leftDrawerHeader' style={{
                marginTop: 64,
            }}>
                <h2>EAT Online</h2>
                {/* <IconButton onClick={() => setLeftDrawer({ status: !leftDrawer?.status, which: leftDrawer.which })}>
                    <ChevronLeftIcon style={{transform: leftDrawer.status ? "rotateZ(0deg)" : "rotateZ(180deg)"}} />
                </IconButton> */}
            </DrawerHeader>
            <List className='LeftSideDrawerList'>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: leftDrawer?.status ? 'initial' : 'center',
                        px: 2.5,
                        backgroundColor: leftDrawer?.which === 0 ? "#f3f3fb" : ""
                    }}
                    onClick={() => handleChange(0)}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: leftDrawer?.status ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <StorefrontIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Şubeler"} sx={{ opacity: leftDrawer?.status ? 1 : 0 }} />
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: leftDrawer?.status ? 'initial' : 'center',
                        px: 2.5,
                        backgroundColor: leftDrawer?.which === 1 ? "#f3f3fb" : ""
                    }}
                    onClick={() => handleChange(1)}

                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: leftDrawer?.status ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <ClassIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Kategoriler"} sx={{ opacity: leftDrawer?.status ? 1 : 0 }} />
                </ListItemButton>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: leftDrawer?.status ? 'initial' : 'center',
                        px: 2.5,
                        backgroundColor: leftDrawer?.which === 2 ? "#f3f3fb" : ""
                    }}
                    onClick={() => handleChange(2)}

                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: leftDrawer?.status ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <FastfoodIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Ürünler"} sx={{ opacity: leftDrawer?.status ? 1 : 0 }} />
                </ListItemButton>
                {
                    user?.isAdmin ?
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: leftDrawer?.status ? 'initial' : 'center',
                                px: 2.5,
                                backgroundColor: leftDrawer?.which === 3 ? "#f3f3fb" : ""
                            }}
                            onClick={() => handleChange(3)}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: leftDrawer?.status ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <Groups2Icon />
                            </ListItemIcon>
                            <ListItemText primary={"Kişiler"} sx={{ opacity: leftDrawer?.status ? 1 : 0 }} />
                        </ListItemButton>
                        : null
                }

            </List>
        </div>
    );
}