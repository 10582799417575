import { createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateProfile } from "firebase/auth";
import { auth, database } from "./FirebaseConfig"
import { useState } from "react";
import TextField from '@mui/material/TextField';
import "./Login.css"
import { equalTo, get, orderByChild, push, query, ref, set } from "firebase/database";
import { enqueueSnackbar } from "notistack";
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Input from '@mui/material/Input';

const Login = () => {

    const [email, setEmail] = useState("")
    const [passWord, setPassWord] = useState("")



    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const checkIfIsUser = async () => {
        const Ref = query(ref(database, 'users/'),
            orderByChild("email"),
            equalTo(email)
        );

        let user = []
        let snapshot = false
        try {
            snapshot = await get(Ref)
            if (snapshot.exists()) {
                user = Object.entries(snapshot.val())[0]
                console.log(snapshot.val(), user);
            } else {
                console.log("No data available");
            }
            if (user[1]?.isLoggedIn !== true && user[1].password !== passWord) throw "Wrong Password"
            return { status: true, user: Object.entries(snapshot.val())[0] }
        } catch (err) {
            console.error("err :", err)
            return { status: false, user: false }
        }



    }
    const loginWithEmailAndPassword = async () => {
        const { status, user } = await checkIfIsUser()
        if (status) {
            try {
               
                if (user[1]?.isLoggedIn !== true) {
                    await createUserWithEmailAndPassword(auth, email, passWord)
                } else {
                    await signInWithEmailAndPassword(auth, email, passWord).catch((err) => { throw 1 })
                }

                enqueueSnackbar(`login success`)


            } catch (err) {
                enqueueSnackbar(`login error, ${err === 1 ? "Email veya şifre hatalı!" : null}`)
                console.error("login error: ", err.code, ",", err.message)
            }
        } else {
            console.error("no such a user")
        }

    }

    const handleForgotPassword = async () => {
        try {
            await sendPasswordResetEmail(auth, email)
            enqueueSnackbar("e-posta gönderildi")
        } catch (err) {
            enqueueSnackbar("Şifre yenileme e-posta hatası!")
        }

    }

    const qrSvg = <svg width="800px" height="800px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.88 16.44V19.89C19.88 20.16 19.66 20.38 19.39 20.38H13.97" stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M13.97 4.62H19.39C19.66 4.62 19.88 4.84 19.88 5.11V10.53" stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M4.12 10.53V5.11C4.12 4.84 4.33999 4.62 4.60999 4.62H10.03" stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M4.12 16.44V19.89C4.12 20.16 4.33999 20.38 4.60999 20.38H10.03" stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M2.48999 14.47H21.51" stroke="#0F0F0F" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


    return (
        <div className={"LoginPageWrapper"}>
            <div className="svgWrapper">
            </div>
            <div className={"LoginFormWrapper"}>
                {qrSvg}
                <h2>Welcome Back!</h2>
                <p>Please enter your details.</p>
                <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="standard"
                    type="email"
                    sx={{ width: 300 }}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <FormControl sx={{ width: 300 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Şifre</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => setPassWord(e.target.value)}
                        label="Şifre"

                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <div className="rememberMeforgotPasswordWrapper">
                    <div className={"rememberMe"}>
                        <div className={"customCheckbox"}>
                            <input type="checkbox" />
                        </div>
                        <p>Remember me</p>
                    </div>

                    <button className="forgotPassword" onClick={() => handleForgotPassword()}>Forgot password?</button>
                </div>
                <button
                    className="loginButton"
                    style={{ marginTop: "45px", height: 50 }}
                    onClick={() => loginWithEmailAndPassword()}
                >Log In</button>
            </div>

        </div>
    );
}

export default Login;
