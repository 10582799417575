import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { isValidAndNotEmptyString } from './utils';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { tr } from 'date-fns/locale'
import { format, setDefaultOptions } from "date-fns";
import Link from '@mui/material/Link';
import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import RemoveDoneRoundedIcon from '@mui/icons-material/RemoveDoneRounded';
import { ref, set } from 'firebase/database';
import { database } from './FirebaseConfig';
import { pendingStore } from './store';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';


const MessageRow = (props) => {
    const { messageData } = props
    const setPending = pendingStore((state) => state.setPending)
    setDefaultOptions({ locale: tr })


    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const stringToColour = (str) => {
        let hash = 0;
        str.split('').forEach(char => {
            hash = char.charCodeAt(0) + ((hash << 5) - hash)
        })
        let colour = '#'
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff
            colour += value.toString(16).padStart(2, '0')
        }
        return colour
    }

    const capitilazeFirstLetter = (str) => {
        let result = str.charAt(0).toUpperCase() + str.slice(1);

        if (!isValidAndNotEmptyString(result)) return undefined
        return result
    }


    const toggleReaded = () => {
        setPending(true)
        try {
            if (messageData[1]?.readed === true) {
                set(ref(database, "feedback/" + messageData[0] + "/readed/"), false)
            } else {
                set(ref(database, "feedback/" + messageData[0] + "/readed/"), true)
            }
        } catch (err) {
            console.log("err", err)
        }
        setPending(false)
    }

    const hiddenTextStyle = {
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxHeight: 40,
        whiteSpace: "wrap",
        minHeight: 40,
    }


    const handleDelete = async(id) => {
        await set(ref(database, `feedback/${id}/`), null)

    }
    console.log(messageData)
    return (
        <Card sx={{ width: window.innerWidth <= 1000 ? "100%" : 600 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: stringToColour(messageData[1].textTwo) }} aria-label="recipe">
                        {
                            isValidAndNotEmptyString(messageData[1].textTwo) ?
                                messageData[1].textTwo[0].toLocaleUpperCase() :
                                null
                        }
                    </Avatar>
                   
                }
                
                action={
                    <IconButton onClick={() => handleDelete(messageData[0])} aria-label="settings">
                        <DeleteIcon />
                    </IconButton>
                }
                title={capitilazeFirstLetter(messageData[1]?.textTwo)}
                subheader={format(new Date(messageData[1].time), 'MMMM i, y') + ", " + messageData[1]?.textThird}
            />
            
            <Collapse in={expanded} collapsedSize={80} timeout="auto">
                <CardContent>
                    {
                        isValidAndNotEmptyString(messageData[1].textOne) ?
                            <Typography variant="body2" style={!expanded ? hiddenTextStyle : null} color="text.secondary">
                                {
                                    expanded ?
                                        messageData[1].textOne :
                                        messageData[1].textOne
                                }
                            </Typography> : null
                    }
                    <Divider sx={{ marginTop: 2 }} />
                    <Stack sx={{ marginTop: 2}} spacing={4}>
                        <Stack spacing={1}>
                            <Typography variant="body3" color="text.title">Genel memnuniyetiniz nasıl?</Typography>
                            <Rating defaultValue={messageData[1].starOne} readOnly={true} size="large" />
                        </Stack>
                        <Stack spacing={1}>
                            <Typography variant="body3" color="text.title">Hijyeni nasıl değerlendirirsiniz?</Typography>
                            <Rating defaultValue={messageData[1].starTwo} readOnly={true} size="large" />
                        </Stack>
                        <Stack spacing={1}>
                            <Typography variant="body3" color="text.title">Yemeklerimizin lezzetini nasıl değerlendirirsiniz?</Typography>
                            <Rating defaultValue={messageData[1]?.starThird} readOnly={true} size="large" />
                        </Stack>
                    </Stack>
                </CardContent>
            </Collapse>

            <CardActions disableSpacing>
                <Tooltip title={"Email ile aç"}>
                    <Link href={`mailto:${messageData[1]?.textThird}`} target='_blank'>
                        <IconButton>
                            <ReplyRoundedIcon />
                        </IconButton>
                    </Link >
                </Tooltip>

                <Tooltip title={messageData[1]?.readed === true ? "Okunmadı olarak işaretle" : "Okundu olarak işaretle"}>
                    <IconButton onClick={() => toggleReaded()}>
                        {
                            messageData[1]?.readed === true ?
                                <RemoveDoneRoundedIcon /> :
                                <DoneAllRoundedIcon />
                        }
                    </IconButton>
                </Tooltip>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>

        </Card>
    )
}

export default MessageRow;