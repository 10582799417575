import { useParams } from 'react-router-dom';
import "./ProductPage.css"
import { languageStore, productsStore, selectedBranchStore } from '../store';
import { useEffect, useRef, useState } from 'react';
import { isValidArrayAndHasLength, isValidObject } from '../utils';
import { tagsArrWithSvg } from './Components/utils';
import Tooltip from '@mui/material/Tooltip';
import { t } from "i18next"
import { equalTo, get, onValue, orderByChild, query, ref, set } from 'firebase/database';
import { database } from '../FirebaseConfig';

const ProductPage = () => {
    let { productId } = useParams()
    const selectedBranch = selectedBranchStore(state => state.selectedBranch)
    const products = productsStore(state => state.products)
    const language = languageStore(state => state.language)

    const [product, setProduct] = useState()
    const [goesWellWith, setGoesWellWith] = useState([])


    const setData = async () => {
        try {
            let val = 0

            let data = await get(ref(database, 'analysis/' + `products/${productId}`));
            if (data.exists()) {
                val = data.val().count
            }
            console.log("val", val)
            await set(ref(database, 'analysis/' + `products/${productId}`), {
                count: val + 1
            });
        } catch (err) {
            console.error(err)
        }
    }
    useEffect(() => {
        setData()
    }, [])

    const findProductById = (id) => {
        if (isValidArrayAndHasLength(selectedBranch) && isValidArrayAndHasLength(products)) {
            const product = products.find((item) => item[0] === id)
            if (!isValidArrayAndHasLength(product)) return

            return {
                ...product[1],
                uid: product[0]
            }
        }
    }
    const findProductByName = (name) => {
        if (!isValidArrayAndHasLength(products)) return
        let find = products?.find((item) => item[0] === name)
        if (!find) return undefined

        return {
            ...find[1],
            uid: find[0]
        }
    }

    const getGoesWell = () => {
        let dummy = []
        product?.goesWell?.map((id) => {
            let productData = findProductById(id)
            dummy.push(productData)
        })
        setGoesWellWith(dummy)
    }

    useEffect(() => {
        if (isValidObject(product)) {
            getGoesWell()
        }
    }, [product])
    useEffect(() => {
        console.log("goesWellWith", goesWellWith)
    }, [goesWellWith])



    useEffect(() => {
        setProduct(findProductByName(productId))
    }, [products])
    useEffect(() => {
        console.log("product", product)
    }, [product])




    const backSvg = <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="header___StyledBiArrowBack-ulvn3-5 jCkWYQ h-6 w-6" _css10="rotate(360deg)" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path></svg>
    const formatter = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2
    });



    return (
        <>
            {
                isValidObject(product) ?
                    < div className="productPageWrapper" >
                        <button className='floatingBackButton' onClick={() => window.history.back()}>{backSvg}</button>
                        <div className='productImageWrapper'>
                            <img src={product?.logo} />
                        </div>
                        <div className={"body"}>
                            <div className='firstWrapper'>
                                <p className='name'>{language === "tr" ? product?.name : product?.nameEN}</p>
                                <p className='description'>{language === "tr" ? product?.description : product?.descriptionEN}</p>
                                <p className='price'>{formatter.format(product?.price)}</p>
                                <div className='tagsWrapper'>
                                    {
                                        tagsArrWithSvg.filter((a) => product?.tags?.map(item => item.toLocaleUpperCase("tr")).includes(a.name.toLocaleUpperCase("tr"))).map((item) => {
                                            return <Tooltip title={language === "tr" ? item.name : item.nameEN} placement="top">
                                                {item.svg}
                                            </Tooltip>

                                        })
                                    }
                                </div>

                                <div className='optionsWrapper'>
                                    {
                                        language === "tr" || !isValidArrayAndHasLength(product?.optionsEN) ?
                                            isValidArrayAndHasLength(product?.options) && product?.options.filter((item) => typeof Number(item?.price) == "number").sort((a, b) => Number(a.price) - Number(b.price)).map((item) => {
                                                return <div>
                                                    <span>{item.name}</span>
                                                    <span>{formatter.format(item.price)}</span>
                                                </div>
                                            })
                                            : isValidArrayAndHasLength(product?.optionsEN) && product?.optionsEN?.filter((item) => typeof Number(item?.price) == "number").sort((a, b) => Number(a.price) - Number(b.price)).map((item) => {
                                                return <div>
                                                    <span>{item.name}</span>
                                                    <span>{formatter.format(item.price)}</span>
                                                </div>
                                            })
                                    }
                                </div>
                            </div>
                            {
                                isValidArrayAndHasLength(goesWellWith) ?
                                    <div className='goesWellWrapper'>
                                        <h3>{t("goes-well-with")}</h3>
                                        {
                                            goesWellWith?.map((item) => {
                                                console.log("findProductById(item)", item)

                                                return <a href={`${item?.name}`} className='goesWellRow'>
                                                    <img src={item?.logo} />
                                                    <div>
                                                        <span>{language === "tr" ? item?.name : item?.nameEN}</span>
                                                        <span>{formatter.format(item?.price)}</span>
                                                    </div>
                                                </a>
                                            })
                                        }
                                    </div> : null
                            }

                        </div>
                    </div > :
                    null
            }
        </>
    )
}

export default ProductPage;