import { useEffect, useState } from "react";
import { languageStore, selectedBranchStore, sideMenuStore } from "../store";
import { isValidArrayAndHasLength, isValidObject } from "../utils";
import "./SideMenu.css";
import { t } from 'i18next';
import { useNavigate } from "react-router-dom"




const SideMenu = () => {

    const sideMenu = sideMenuStore((state) => state.sideMenu)
    const setSideMenu = sideMenuStore((state) => state.setSideMenu)
    const selectedBranch = selectedBranchStore((state) => state.selectedBranch)
    const language = languageStore((state) => state.language)
    const setLanguage = languageStore((state) => state.setLanguage)


    const closeSvg = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="cursor-pointer text-highlight_color"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    const navigate = useNavigate()

    const [brandLogo, setBrandLogo] = useState("")
    const [brandAddress, setBrandAddress] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [isLanguageSelect, setIsLanguageSelect] = useState(false)
    const [instagramLink, setInstagramLink] = useState(false)



    useEffect(() => {
        if (isValidObject(selectedBranch) && isValidArrayAndHasLength(Object.entries(selectedBranch))) {
            setBrandLogo(Object.entries(selectedBranch)[0][1]?.logo)
            setBrandAddress(Object.entries(selectedBranch)[0][1]?.address)
            setPhoneNumber(Object.entries(selectedBranch)[0][1]?.tellNo)
            setInstagramLink(Object.entries(selectedBranch)[0][1]?.instagramLink)
        }
    }, [selectedBranch])

    const instagramSvg = <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
    const whatsuppSvg = <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path></svg>
    const goBackSvg = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="cursor-pointer text-highlight_color"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>


    return (
        <div className={sideMenu ? "SideMenuWrapper open" : "SideMenuWrapper"} style={{ justifyContent: isLanguageSelect ? "flex-start" : "space-between" }}>
            {
                !isLanguageSelect ?
                    <>
                        <div style={{ width: "100%", height: "auto" }}>
                            <div className="header">
                                <button onClick={() => setSideMenu(false)}>{closeSvg}</button>
                            </div>
                            <div className="logoRow">
                                <img src={brandLogo} />
                            </div>
                            <div className={"linksCol"}>
                                <button onClick={() => setIsLanguageSelect(true)}>{t("language") + " (" + language.toUpperCase() + ")"}</button>
                                <a onClick={() => {
                                    navigate("Feedback")
                                    setSideMenu(false)
                                }}>
                                    {t("giveFeedback")}
                                </a>
                                <a href={`tel:+90${phoneNumber}`}>{t("callRestourant")}</a>
                            </div>
                            <div className="seperator"></div>
                        </div>
                        <div className="footer">
                            <p>{brandAddress}</p>
                            <div className="buttonsWrapper">
                                <a href={instagramLink} target="_blank">{instagramSvg}</a>
                                <a href={`https://wa.me/${phoneNumber}`} target="_blank">{whatsuppSvg}</a>
                            </div>
                        </div>
                    </>
                    : <>
                        <div className="header">
                            <button onClick={() => setIsLanguageSelect(false)}>{goBackSvg}</button>
                        </div>
                        <div className={"linksCol"} style={{ marginTop: 20 }}>
                            <button className={language === "tr" ? "active" : ""} onClick={() => setLanguage("tr")}>Türkçe</button>
                            <button className={language === "en" ? "active" : ""} onClick={() => setLanguage("en")}>English</button>
                        </div>
                    </>
            }
        </div>
    )
}

export default SideMenu;