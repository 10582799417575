import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { deleteObject, getDownloadURL, ref as storageRef } from 'firebase/storage';
import { auth, database, storage } from './FirebaseConfig';
import Skeleton from '@mui/material/Skeleton';
import { branchesDrawerStore, dialogStore, pendingStore, productsStore, selectedBranchStore, selectedCategoryStore, selectedProductStore } from './store';
import { isValidAndNotEmptyString, isValidArrayAndHasLength } from './utils';
import "./BranchCard.css"
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ref, remove } from 'firebase/database';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { useNavigate } from "react-router-dom"
import { stringToColour } from './API';
import Tooltip from '@mui/material/Tooltip';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function CustomCard(props) {
    const { data } = props;

    const navigate = useNavigate()

    const setDialog = dialogStore((state) => state.setDialog)

    const setPending = pendingStore((state) => state.setPending)

    const selectedBranch = selectedBranchStore((state) => state.selectedBranch)
    const setSelectedBranch = selectedBranchStore((state) => state.setSelectedBranch)
    const setIsBranchesDrawerOpen = branchesDrawerStore((state) => state.setIsBranchesDrawerOpen)
    const products = productsStore((state) => state.products)

    const [expanded, setExpanded] = React.useState(false);
    const [url, setUrl] = React.useState("");
    const [imageError, setImageError] = React.useState(false);


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const aylar = [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık"
    ];

    let date = new Date(data[1].createdAt)

    React.useEffect(() => {
        console.log("imageError", imageError)
    }, [imageError])


    const showConfirmationPopup = () => {
        return new Promise((resolve) => {
            setDialog({ status: true, title: "Dikkat", message: `"${data[1].name}" şubenizi silmek üzeresiniz.`, resolve: resolve })
        });
    };

    const handleDeleteBranch = async () => {
        const userConfirmed = await showConfirmationPopup();

        if (userConfirmed) {
            setPending(true)
            console.error("silindi", data[0])
            try {
                // deleteObject(storageRef(storage, `branches/${data[1].name}`)).then(() => {
                // }).catch((error) => {
                // });
                await remove(ref(database, `branches/${data[0]}/`))
            } catch (err) {
                console.error("handleDeleteBranch error: ", err)
            }
            setPending(false)
        }
    }



    return (
        <div className={"BranchCardWrapper"}>
            <Card
                onClick={() => setSelectedBranch(data)}
                className={Array.isArray(selectedBranch) && selectedBranch[0] === data[0] ? "cardRow active" : "cardRow"}
                style={{
                    width: "100%",
                    height: 50,
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 10,
                }}
                sx={[
                    {
                        '&:hover': {
                            transition: ".2s",
                            backgroundColor: "#1976d240"
                        },
                    },

                ]}>

                <div className={"row-first-col"}>
                    {
                        isValidAndNotEmptyString(data[1]?.logo) && !imageError
                            ? <img
                                src={data[1]?.logo}
                                onError={() => setImageError(true)}
                            />
                            : <Skeleton variant="rounded" width={100} height={"100%"} />
                    }
                    <div className='titleAndDateWrapper'>
                        <h2>{data[1].name}</h2>
                        <h3>{aylar[date.getMonth()]} {date.getDate()}, {date.getFullYear()}</h3>
                    </div>

                </div>

                <div className='buttonsWrapper'>
                    {
                        isValidAndNotEmptyString(data[1]?.owner) ?
                            <Tooltip arrow title={data[1].owner} placement="top-start">
                                <Avatar className='avatarWrapper' sx={{ bgcolor: stringToColour(data[1]?.owner) }}>
                                    {data[1].owner[0].toUpperCase()}
                                </Avatar>
                            </Tooltip>
                            : null
                    }
                    <IconButton onClick={() => setIsBranchesDrawerOpen({ status: true, type: "update", data: data })} aria-label="delete">
                        <ModeEditIcon />
                    </IconButton>
                    <IconButton onClick={() => navigate(`/${data[1]?.name}`)} aria-label="preview">
                        <VisibilityRoundedIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteBranch()} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </div>

            </Card>
        </div>
    );
}