import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { branchesDrawerStore, categoryDrawerStore, pendingStore, productEditDrawerStore, selectedBranchStore } from './store';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import ImagePicker from './ImagePicker';
import { child, get, onValue, push, ref, set } from 'firebase/database';
import { database, storage } from './FirebaseConfig';
import { uploadBytes, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { useSnackbar } from 'notistack';
import { isValidAndNotEmptyString, isValidArrayAndHasLength, isValidObject } from './utils';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { showConfirmationPopup, validatePhoneNumber } from './API';
import { useSwipeable } from 'react-swipeable';


export default function BranchesDrawer() {
    const { enqueueSnackbar } = useSnackbar();

    const isBranchesDrawerOpen = branchesDrawerStore((state) => state.isBranchesDrawerOpen)
    const setIsBranchesDrawerOpen = branchesDrawerStore((state) => state.setIsBranchesDrawerOpen)

    const [img, setImg] = React.useState(null)
    const [name, setName] = React.useState("")
    const [slogan, setSlogan] = React.useState("")
    const [address, setAddress] = React.useState("")
    const [tellNo, setTellNo] = React.useState("")
    const [instagramLink, setInstagramLink] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [warningText, setWarningText] = React.useState("")
    const [warningTextEN, setWarningTextEN] = React.useState("")
    const selectedBranch = selectedBranchStore((state) => state.selectedBranch)
    const setPending = pendingStore((state) => state.setPending)
    const [logo, setLogo] = React.useState("")





    React.useEffect(() => {
        if (isBranchesDrawerOpen.status === true && isBranchesDrawerOpen.type == "update") {
            setName(isBranchesDrawerOpen.data[1].name || "")
            setDescription(isBranchesDrawerOpen.data[1].description || "")
            setSlogan(isBranchesDrawerOpen.data[1].slogan || "")
            setAddress(isBranchesDrawerOpen.data[1].address || "")
            setTellNo(isBranchesDrawerOpen.data[1].tellNo || "")
            setInstagramLink(isBranchesDrawerOpen.data[1].instagramLink || "")
            setWarningText(isBranchesDrawerOpen.data[1]?.warningText || "")
            setWarningTextEN(isBranchesDrawerOpen.data[1]?.warningTextEN || "")
            setLogo(isBranchesDrawerOpen.data[1]?.logo || "")
        } else if (isBranchesDrawerOpen.status === true && isBranchesDrawerOpen.type == "new") {
            setName("")
            setDescription("")
            setSlogan("")
            setAddress("")
            setTellNo("")
            setInstagramLink("")
            setWarningText("")
            setWarningTextEN("")
            setLogo("")
        }
    }, [isBranchesDrawerOpen.status])

    const uploadImage = async (file, uid) => {
        const imagesRef = storageRef(storage, `branches/${uid}/logo/image`);
        await uploadBytes(imagesRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!', snapshot);
        });
    }

    const createBranchesService = async () => {
        try {
            if (img === null) throw "1"
            if (!validatePhoneNumber(tellNo)) throw "2"

            setPending(true)
            const date = new Date()
            const uid = await push(child(ref(database), 'branches/')).key;
            await uploadImage(img, uid)
            let url = await getDownloadURL(storageRef(storage, `branches/${uid}/logo/image`))
            let ress = await set(ref(database, `branches/${uid}`), {
                name: name.toLocaleLowerCase("tr"),
                createdAt: date.getTime(),
                description: description,
                slogan: slogan,
                address: address,
                tellNo: tellNo,
                instagramLink: instagramLink,
                logo: url,
                warningText: warningText,
                warningTextEN: warningTextEN
            })

            enqueueSnackbar("Şube eklendi")
            setPending(false)
            setIsBranchesDrawerOpen({ status: false, type: "new" })

        } catch (err) {
            console.error("createBranchesService err: ", img, err)
            enqueueSnackbar(`Şube eklenemedi ${err == 1 ? "resim seçilmedi!" : "telefon numarası 0 olmadan başlamalı ve özel karakter içermemeli"} `)
        }
        setPending(false)

    }

    const updateBranchesService = async () => {
        try {
            if (!validatePhoneNumber(tellNo)) throw "2"
            setPending(true)
            const date = new Date()
            let url = ""
            if (img !== null) {
                await uploadImage(img, isBranchesDrawerOpen.data[0])
                url = await getDownloadURL(storageRef(storage, `branches/${isBranchesDrawerOpen.data[0]}/logo/image`))
            } else if (isValidAndNotEmptyString(logo)) {
                url = logo
            } else {
                url = await getDownloadURL(storageRef(storage, `default/default.jpg`))
            }
            await set(ref(database, `branches/${isBranchesDrawerOpen.data[0]}/`), {
                name: name.toLocaleLowerCase("tr"),
                createdAt: date.getTime(),
                description: description,
                logo: url,
                slogan: slogan,
                address: address,
                tellNo: tellNo,
                owner: isBranchesDrawerOpen.data[1].owner || null,
                instagramLink: instagramLink,
                categories: isBranchesDrawerOpen.data[1]?.categories || null,
                warningText: warningText,
                warningTextEN: warningTextEN
            })
            enqueueSnackbar("Şube güncellendi")
            setIsBranchesDrawerOpen({ status: false, type: "new" })
            setPending(false)
        } catch (err) {
            console.error("createBranchesService err: ", err)
            enqueueSnackbar(`Şube güncellenemedi ${err == 1 ? "resim seçilmedi!" : "telefon numarası 0 olmadan başlamalı ve özel karakter içermemeli"} `)
        }
    }



    const handleClose = async () => {
        let confirm = await showConfirmationPopup("Kayıt edilmemiş değişiklikleriniz olabilir!")
        if (!confirm) return
        setIsBranchesDrawerOpen({ status: false, type: "new" })
    }


    const handlers = useSwipeable({
        onSwipedRight: (e) => e.deltaX > 100 ? setIsBranchesDrawerOpen({ status: false, type: "new" }) : null,
    });

    return (
        <div>

            <React.Fragment key={"anchor"}>
                <Fab
                    onClick={() => setIsBranchesDrawerOpen({ status: !isBranchesDrawerOpen.status, type: "new" })}
                    size="medium"
                    className="fab"
                    color="" aria-label="add"
                >
                    <AddIcon />
                </Fab>
                {/* <Button onClick={() => setIsBranchesDrawerOpen({ status: !isBranchesDrawerOpen.status, type: "new" })}>{"Yeni Şube Ekle"}</Button> */}
                <Drawer
                    {...handlers}
                    anchor={"right"}
                    open={isBranchesDrawerOpen.status}
                    onClose={() => handleClose()}
                >
                    <div className='drawerBody'>
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Şube İsimi"
                            variant="outlined"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Şube Slogan"
                            variant="outlined"
                            type="text"
                            value={slogan}
                            onChange={(e) => setSlogan(e.target.value)}
                        />
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Şube Adres"
                            variant="outlined"
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Şube Telefon Numarası:"
                            variant="outlined"
                            type="text"
                            value={tellNo}
                            onChange={(e) => setTellNo(e.target.value)}
                        />
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="İnstagram Linki:"
                            variant="outlined"
                            type="text"
                            value={instagramLink}
                            onChange={(e) => setInstagramLink(e.target.value)}
                        />
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Uyarı Yazısı:"
                            variant="outlined"
                            type="text"
                            value={warningText}
                            onChange={(e) => setWarningText(e.target.value)}
                        />
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Uyarı Yazısı EN:"
                            variant="outlined"
                            type="text"
                            value={warningTextEN}
                            onChange={(e) => setWarningTextEN(e.target.value)}
                        />
                        <ImagePicker val={img} set={setImg} defaultValue={logo} setDefaultValue={setLogo} customStyle={{ width: "100%", height: 150 }} />
                        {
                            isBranchesDrawerOpen.type === "update" ?
                                <button className='drawerButton' onClick={() => updateBranchesService()}>Kaydet</button> :
                                <button className='drawerButton' onClick={() => createBranchesService()}>Yeni Şube Oluştur</button>
                        }

                    </div>
                </Drawer>
            </React.Fragment>
        </div >
    );
}