import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { branchesStore, categoriesStore, categoryDrawerStore, dialogStore, pendingStore, productDrawerStore, productsStore, selectedBranchStore, selectedCategoriesStore, selectedCategoryStore, selectedProductsStore } from './store';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import ImagePicker from './ImagePicker';
import { child, get, onValue, push, ref, set } from 'firebase/database';
import { database, storage } from './FirebaseConfig';
import { uploadBytes, ref as storageRef, getDownloadURL } from 'firebase/storage';
import InputAdornment from '@mui/material/InputAdornment';
import ChipSelect from './ChipSelect';
import MultipleGroupedSelect from './MultipleGroupedSelect';
import { isValidAndNotEmptyString, isValidArrayAndHasLength } from './utils';
import "./ProductDrawer.css"
import Chip from '@mui/material/Chip';
import { Transition, useSnackbar } from 'notistack';

import Textarea from '@mui/joy/Textarea';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import defaultPng from "../src/images/default-product.png"
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Collapse from '@mui/material/Collapse';
import Grow from '@mui/material/Grow';
import { showConfirmationPopup } from './API';
import { useSwipeable } from 'react-swipeable';



export default function ProductDrawer() {
    const { enqueueSnackbar } = useSnackbar();

    const isProductDrawerOpen = productDrawerStore((state) => state.isProductDrawerOpen)
    const setIsProductDrawerOpen = productDrawerStore((state) => state.setIsProductDrawerOpen)

    const selectedCategories = selectedCategoriesStore((state) => state.selectedCategories)
    const selectedProducts = selectedProductsStore((state) => state.selectedProducts)
    const products = productsStore((state) => state.products)
    const categories = categoriesStore((state) => state.categories)
    const [options, setOptions] = React.useState([])
    const [optionsEN, setOptionsEN] = React.useState([])

    React.useEffect(() => {
        // console.log("options", options)
    }, [options])



    const [img, setImg] = React.useState(null)
    const [name, setName] = React.useState("")
    const [nameEN, setNameEN] = React.useState("")
    const [price, setPrice] = React.useState("")
    const [logo, setLogo] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [descriptionEN, setDescriptionEN] = React.useState("")
    const selectedBranch = selectedBranchStore((state) => state.selectedBranch)
    const selectedCategory = selectedCategoryStore((state) => state.selectedCategory)
    const setPending = pendingStore((state) => state.setPending)

    const [optionName, setOptionName] = React.useState("")
    const [optionNameEN, setOptionNameEN] = React.useState("")
    const [optionPrice, setOptionPrice] = React.useState("")
    const [optionPriceEN, setOptionPriceEN] = React.useState("")

    const [isOptionENAvailable, setIsOptionENAvailable] = React.useState(false)


    const [tags, setTags] = React.useState([])

    const [goesWell, setGoesWell] = React.useState([])

    const [categoryPreferencesForProduct, setCategoryPreferencesForProduct] = React.useState(isValidArrayAndHasLength(selectedCategory) ? selectedCategory[0] : false);



    React.useEffect(() => {
        if (isValidArrayAndHasLength(selectedCategory)) {
            setCategoryPreferencesForProduct(selectedCategory[0])
        }
    }, [selectedCategory])





    const handleChange = (event) => {
        setCategoryPreferencesForProduct(event.target.value);
    };

    React.useEffect(() => {
        if (isProductDrawerOpen.status === false) {
            setCategoryPreferencesForProduct(false)
        } else {
            setCategoryPreferencesForProduct(isValidArrayAndHasLength(selectedCategory) ? selectedCategory[0] : false)
        }
    }, [isProductDrawerOpen.status])

    React.useEffect(() => {
        if (isProductDrawerOpen.status === true && isProductDrawerOpen.type == "update") {
            setName(isProductDrawerOpen.data[1].name || "")
            setDescription(isProductDrawerOpen.data[1].description || "")

            setNameEN(isProductDrawerOpen.data[1]?.nameEN || "")
            setDescriptionEN(isProductDrawerOpen.data[1]?.descriptionEN || "")

            setGoesWell(isProductDrawerOpen.data[1].hasOwnProperty("goesWell") && isValidArrayAndHasLength(isProductDrawerOpen.data[1].goesWell) ? isProductDrawerOpen.data[1]?.goesWell : [])
            setTags(isProductDrawerOpen.data[1]?.tags || [])

            setPrice(isProductDrawerOpen.data[1]?.price || "")
            setOptions(isProductDrawerOpen.data[1].hasOwnProperty("options") && isValidArrayAndHasLength(isProductDrawerOpen.data[1].options) ? isProductDrawerOpen.data[1]?.options : [])
            setOptionsEN(isProductDrawerOpen.data[1]?.hasOwnProperty("optionsEN") && isValidArrayAndHasLength(isProductDrawerOpen.data[1]?.optionsEN) ? isProductDrawerOpen.data[1]?.optionsEN : [])
            setCategoryPreferencesForProduct(isProductDrawerOpen?.data[1]?.category || "")

            setLogo(isProductDrawerOpen?.data[1]?.logo)

            setOptionName("")
            setOptionNameEN("")
            setOptionPrice("")
            setOptionPriceEN("")

        } else if (isProductDrawerOpen.status === true && isProductDrawerOpen.type == "new") {
            setName("")
            setNameEN("")
            setDescription("")
            setDescriptionEN("")
            setImg(null)

            setGoesWell([])
            setTags([])

            setPrice("")
            setOptions([])
            setOptionsEN([])

            setLogo("")

            setOptionName("")
            setOptionNameEN("")
            setOptionPrice("")
            setOptionPriceEN("")
        }
    }, [isProductDrawerOpen.status])

    const uploadImage = async (file, productName) => {
        const imagesRef = storageRef(storage, `products/${productName}/logo/image`);
        await uploadBytes(imagesRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!', snapshot);
        });
    }

    const createProductService = async () => {
        console.log(img)
        if (isValidArrayAndHasLength(selectedBranch) && isValidArrayAndHasLength(selectedCategory)) {
            setPending(true)
            const date = new Date()
            let uid = await push(child(ref(database), `products/`)).key;
            let url = ""
            if (img) {
                await uploadImage(img, uid)
                url = await getDownloadURL(storageRef(storage, `products/${uid}/logo/image`))
            } else {
                url = await getDownloadURL(storageRef(storage, `default/default.jpg`))
            }


            let data = {
                name: name,
                nameEN: nameEN,
                createdAt: date.getTime(),
                description: description,
                descriptionEN: descriptionEN,
                logo: url,
                goesWell: goesWell,
                tags: tags,
                price: price,
                branch: selectedBranch[0],
                category: categoryPreferencesForProduct,
                options: options,
                optionsEN: optionsEN?.length > 0 ? optionsEN : null,
            }

            await set(ref(database, `products/${uid}/`), data)

            enqueueSnackbar("ürün eklendi")
            setIsProductDrawerOpen({ status: false, type: "new" })
            setPending(false)
        } else {
            console.error("createProductService err: ")
            enqueueSnackbar("Resim seçmediniz")
        }
    }
    const updateProductService = async () => {
        if (isValidArrayAndHasLength(selectedBranch) && isValidArrayAndHasLength(selectedCategory)) {
            setPending(true)
            const date = new Date()
            let url = ""
            if (img !== null) {
                await uploadImage(img, isProductDrawerOpen.data[0])
                url = await getDownloadURL(storageRef(storage, `products/${isProductDrawerOpen.data[0]}/logo/image`))
            } else if (isValidAndNotEmptyString(logo)) {
                url = logo
            } else {
                url = await getDownloadURL(storageRef(storage, `default/default.jpg`))
            }

            let data = {
                name: name,
                nameEN: nameEN,
                createdAt: date.getTime(),
                description: description,
                descriptionEN: descriptionEN,
                logo: url,
                goesWell: goesWell,
                tags: tags,
                price: price,
                branch: selectedBranch[0],
                category: categoryPreferencesForProduct,
                options: options,
                optionsEN: optionsEN?.length > 0 ? optionsEN : null,

            }
            await set(ref(database, `products/${isProductDrawerOpen.data[0]}/`), data)

            enqueueSnackbar("Ürün güncellendi")
            setPending(false)
            setIsProductDrawerOpen({ status: false, type: "new" })
        } else {
            console.error("updateProductService err: ")
            enqueueSnackbar("Şube yada kategori seçmediniz")
        }
    }

    const [allProducts, setAllProducts] = React.useState([])

    React.useEffect(() => {
        if (isValidArrayAndHasLength(products)) {
            setAllProducts(products?.map((item) => ({ ...item[1], uid: item[0] })))
        }
    }, [selectedBranch])

    const addOption = () => {
        setOptions([...options, { name: optionName, price: optionPrice }])
        setOptionName("")
        setOptionPrice("")
    }
    const addOptionEN = () => {
        setOptionsEN([...optionsEN, { name: optionNameEN, price: optionPriceEN }])
        setOptionNameEN("")
        setOptionPriceEN("")
    }
    const handleDelete = (a) => {
        setOptions(options.filter((_, index) => index !== a))
    }
    const handleDeleteEN = (a) => {
        setOptionsEN(optionsEN.filter((_, index) => index !== a))
    }

    const handleEditOption = (price, name, index) => {
        let updated = options.map((item, i) => {
            if (i === index) {
                return { name: name, price: price }
            } else {
                return item
            }
        })
        setOptions(updated)
    }
    const handleEditOptionEN = (price, name, index) => {
        let updated = optionsEN.map((item, i) => {
            if (i === index) {
                return { name: name, price: price }
            } else {
                return item
            }
        })
        setOptionsEN(updated)
    }

    const handleClose = async () => {
        let confirm = await showConfirmationPopup("Kayıt edilmemiş değişiklikleriniz olabilir!")
        if (!confirm) return
        setIsProductDrawerOpen({ status: false, type: "new" })
    }



    const handlers = useSwipeable({
        onSwipedRight: (e) => e?.deltaX > 100 ? setIsProductDrawerOpen({ status: false, type: "new" }) : null,
    });

    return (
        <div >
            <React.Fragment key={"anchor"}>
                <Fab
                    onClick={() => setIsProductDrawerOpen({ status: !isProductDrawerOpen.status, type: "new" })}
                    size="medium"
                    className="fab productDrawerButton"
                    color="" aria-label="add"
                >
                    <AddIcon />
                </Fab>
                {/* <Button onClick={() => setIsProductDrawerOpen({ status: !isProductDrawerOpen.status, type: "new" })}>{"Yeni Ürün Ekle"}</Button> */}
                <Drawer
                    {...handlers}
                    className='drawer'
                    anchor={"right"}
                    open={isProductDrawerOpen.status}
                    onClose={() => handleClose()}
                >

                    <div className='drawerBody'>
                        <Box sx={{ width: "100%" }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Kategori</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={categoryPreferencesForProduct}
                                    label="Kategori"
                                    onChange={(e) => handleChange(e)}
                                >
                                    {
                                        isValidArrayAndHasLength(selectedCategories) && selectedCategories.filter((item) => item[1].name).map((item) => {
                                            let timeStamp = new Date().getTime()
                                            // console.log("item", item)
                                            return <MenuItem value={item[0]}>
                                                {`${item[1].name}`}
                                            </MenuItem>
                                        })
                                    }

                                </Select>
                            </FormControl>
                        </Box>
                        <div className='row'>
                            <TextField
                                style={{ width: "100%" }}
                                id="outlined-basic"
                                label="Ürün ismi"
                                variant="outlined"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <TextField
                                style={{ width: "100%" }}
                                id="outlined-basic"
                                label="Product Name EN"
                                variant="outlined"
                                type="text"
                                value={nameEN}
                                onChange={(e) => setNameEN(e.target.value)}
                            />
                        </div>
                        <div className='col'>
                            <div className='textArea'>
                                <Textarea
                                    placeholder="Ürün Açıklama"
                                    style={{ width: "100%", height: "100%" }}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>

                            <div className='textArea'>
                                <Textarea
                                    placeholder="Ürün Açıklama"
                                    style={{ width: "100%", height: "100%" }}
                                    value={descriptionEN}
                                    onChange={(e) => setDescriptionEN(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <TextField
                                style={{ width: "50%" }}
                                id="outlined-basic"
                                label="Ürün Fiyat"
                                variant="outlined"
                                type="number"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start" >₺</InputAdornment>,
                                }}
                            />

                            <ChipSelect val={tags} set={setTags} />
                        </div>
                        {
                            isValidArrayAndHasLength(isProductDrawerOpen?.data) ?
                                <MultipleGroupedSelect label={"Yanında iyi Gider"} val={goesWell} set={setGoesWell} dataArr={allProducts.filter((item) => item?.uid !== isProductDrawerOpen?.data[0])} />
                                : null
                        }
                        <ImagePicker val={img} set={setImg} defaultValue={logo} setDefaultValue={setLogo} customStyle={{ width: "100%", height: 130 }} />


                        <div className='switchAndLabelWrapper'>
                            <FormControlLabel
                                sx={{ "& .MuiSwitch-thumb": { background: "#1b1b1b !important" }, "& .Mui-checked+.MuiSwitch-track": { background: "#1b1b1b !important" } }}
                                control={<Switch checked={isOptionENAvailable}
                                    onChange={(_, val) => setIsOptionENAvailable(val)} variant="" />}
                                label="İngilizce Opsiyonları Göster" />
                        </div>
                        <Collapse in={isOptionENAvailable}>
                            <div className='addOptionsWrapper'>
                                <Divider style={{ width: "100%", marginBottom: 15 }}>EN</Divider>
                                <div className='inputsWarapper'>
                                    <TextField
                                        style={{ width: "50%" }}
                                        he
                                        id="outlined-basic"
                                        label="description"
                                        variant="outlined"
                                        type="text"
                                        value={optionNameEN}
                                        onChange={(e) => setOptionNameEN(e.target.value)}
                                    />

                                    <TextField
                                        style={{ width: "50%" }}
                                        id="outlined-basic"
                                        label="₺"
                                        variant="outlined"
                                        type="number"
                                        value={optionPriceEN}
                                        onChange={(e) => setOptionPriceEN(e.target.value)}
                                    />
                                    <Button style={{ width: "30%", height: 56 }} onClick={() => addOptionEN()} variant="outlined">Gramaj Ekle</Button>
                                </div>
                                <div className='optionChipsWrapper'>

                                    {
                                        optionsEN.map((item, index) => {
                                            return <div
                                                key={index}
                                                className={"optionRow"}>

                                                <TextField
                                                    style={{ width: "100%" }}
                                                    id="outlined-basic"
                                                    label="isim"
                                                    variant="outlined"
                                                    type="text"
                                                    value={item.name}
                                                    onChange={(e) => handleEditOptionEN(item.price, e.target.value, index)}
                                                />
                                                <TextField
                                                    style={{ width: "30%" }}
                                                    id="outlined-basic"
                                                    label="fiyat"
                                                    variant="outlined"
                                                    type="number"
                                                    value={item.price}
                                                    onChange={(e) => handleEditOptionEN(e.target.value, item.name, index)}
                                                />

                                                <IconButton
                                                    onClick={() => handleDeleteEN(index)}
                                                    aria-label="delete"
                                                    size="small"
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </div>
                                        })
                                    }
                                </div>

                            </div>
                        </Collapse>
                        <Divider style={{ width: "100%" }}>TR</Divider>

                        <div className='addOptionsWrapper'>
                            <div className='inputsWarapper'>
                                <TextField
                                    style={{ width: "50%" }}
                                    he
                                    id="outlined-basic"
                                    label="açıklama"
                                    variant="outlined"
                                    type="text"
                                    value={optionName}
                                    onChange={(e) => setOptionName(e.target.value)}
                                />

                                <TextField
                                    style={{ width: "50%" }}
                                    id="outlined-basic"
                                    label="₺"
                                    variant="outlined"
                                    type="number"
                                    value={optionPrice}
                                    onChange={(e) => setOptionPrice(e.target.value)}
                                />
                                <Button style={{ width: "30%", height: 56 }} onClick={() => addOption()} variant="outlined">Gramaj Ekle</Button>
                            </div>


                            <div className='optionChipsWrapper'>
                                {/* {
                                    options.map((item) => {
                                        return <Chip
                                            label={item.name + " | " + item.price}
                                            variant="outlined"
                                            style={{ height: 35 }}
                                            onDelete={() => handleDelete(item.name)}
                                        />
                                    })
                                } */}
                                {
                                    options.map((item, index) => {
                                        return <div
                                            key={index}
                                            className={"optionRow"}>
                                            {/* <input
                                            label={ + " | " + item.price}
                                            variant="outlined"
                                            style={{ height: 35 }}
                                            onDelete={() => handleDelete(item.name)}
                                        /> */}
                                            <TextField
                                                style={{ width: "100%" }}
                                                id="outlined-basic"
                                                label="isim"
                                                variant="outlined"
                                                type="text"
                                                value={item.name}
                                                onChange={(e) => handleEditOption(item.price, e.target.value, index)}
                                            />
                                            <TextField
                                                style={{ width: "30%" }}
                                                id="outlined-basic"
                                                label="fiyat"
                                                variant="outlined"
                                                type="number"
                                                value={item.price}
                                                onChange={(e) => handleEditOption(e.target.value, item.name, index)}
                                            />

                                            <IconButton
                                                onClick={() => handleDelete(index)}
                                                aria-label="delete"
                                                size="small"
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </div>
                                    })
                                }
                            </div>
                        </div>

                        {
                            isProductDrawerOpen.type == "new"
                                ? <button className='drawerButton' onClick={() => createProductService()} variant="outlined">Ürünü Ekle</button>
                                : <button className='drawerButton' onClick={() => updateProductService()} variant="outlined">kaydet</button>
                        }


                        <Divider />

                    </div>
                </Drawer>
            </React.Fragment>
        </div >
    );
}