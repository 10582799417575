import React, { useState } from "react"
import CustomCard from "./CustomCard.js"
import Pagination from '@mui/material/Pagination'
import Box from '@mui/material/Box';
import { branchesStore, categoriesStore, pendingStore, productsStore, selectedProductsStore } from "./store.js";
import { push, ref } from "firebase/database";
import { database, storage } from "./FirebaseConfig.js";
import CurrentBranchesDialog from "./CurrentBranchesDialog.js";
import { uploadBytes } from "firebase/storage";
import BranchesDrawer from "./BracnhesDrawer.js";
import "./BranchesComponent.css"
import { isValidArrayAndHasLength, isValidObject } from "./utils.js";



const BrachesComponent = () => {

    const branches = branchesStore((state) => state.branches)
    const setBranches = branchesStore((state) => state.setBranches)
    const selectedProducts = selectedProductsStore((state) => state.selectedProducts)
    const products = productsStore(state => state.products)
    const categories = categoriesStore(state => state.categories)

    const [page, setPage] = useState(1)

    const setPending = pendingStore((state) => state.setPending)
    const pending = pendingStore((state) => state.pending)

    console.log("branches", (branches))


    const style = {
        boxStyle: {
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
            minHeight: 350,
        },
        boxStyleCol: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
        }
    }

    return (
        <Box style={style.boxStyleCol}>
            <div className="countBoxsWrapper">
                <div className="countBox">
                    <h5>Aktif Şubeler</h5>
                    <span>{isValidArrayAndHasLength(branches) ? branches?.length : "-"}</span>
                </div>
                <div className="countBox">
                    <h5>Aktif Kategoriler</h5>
                    <span>
                        {isValidArrayAndHasLength(categories) ? categories?.length : "-"}
                    </span>
                </div>
                <div className="countBox">
                    <h5>Aktif Ürünler</h5>
                    <span>
                        {isValidArrayAndHasLength(products) ? products?.length : "-"}
                    </span>
                </div>
            </div>
            {/* <CurrentBranchesDialog /> */}
            <div className={"tableHead"}>
                <h2 className="section-title">Şubeler</h2>
                <BranchesDrawer />
            </div>

            <Box style={style.boxStyle}>
                {
                    isValidArrayAndHasLength(branches) ? branches?.filter((item, i) => i < 10 * page && i >= 10 * (page - 1)).map((item) => <CustomCard key={item[0]} data={item} />) : null
                }
            </Box>
            <Pagination className="pagination" count={Math.ceil(branches.length / 10)} onChange={(e, val) => setPage(val)} variant="outlined" />
        </Box>
    )
}


export default BrachesComponent