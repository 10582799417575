import { t } from "i18next"
import "./Header.css"
import {selectedBranchStore, sideMenuStore } from "../../store"
import { useEffect, useState } from "react"
import { isValidArrayAndHasLength, isValidObject } from "../../utils"


const Header = () => {

const goBackSvg = <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" class="w-6 h-6 cursor-pointer text-background_color transition-[transform] duration-300 active:-translate-x-1.5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"></path></svg>
const menuSvg = <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" class="w-6 h-6 text-background_color" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16"></path></svg>  
const setSideMenu = sideMenuStore((state) => state.setSideMenu)
const sideMenu = sideMenuStore((state) => state.sideMenu)

const selectedBranch = selectedBranchStore(state => state.selectedBranch)
const [brandName, setBrandName] = useState("")


useEffect(() => {
    if(isValidObject(selectedBranch) && isValidArrayAndHasLength(Object.entries(selectedBranch))) {
        setBrandName(Object.entries(selectedBranch)[0][1].name)
    }
}, [selectedBranch])

return (
        <div className={"HeaderWrapper"}>
            <button onClick={() => window.history.go(-1)} className="goBackButton">{goBackSvg}</button>

            <h1>{brandName.toLocaleUpperCase("tr")}</h1>
            <button onClick={() => setSideMenu(!sideMenu)} className="menuButton">
                {menuSvg}
            </button>
        </div>
    )
}

export default Header;