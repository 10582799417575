import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { branchesDrawerStore, branchesStore, categoryDrawerStore, pendingStore, productEditDrawerStore, selectedBranchStore, usersDrawerStore } from '../../store';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import ImagePicker from '../../ImagePicker';
import { child, equalTo, get, onValue, orderByChild, push, query, ref, set, update } from 'firebase/database';
import { auth, database, storage } from '../../FirebaseConfig';
import { uploadBytes, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { useSnackbar } from 'notistack';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { showConfirmationPopup } from '../../API';
import SelectBranchesForUser from './SelectBranchesForUser';
import { isValidArrayAndHasLength, isValidObject } from '../../utils';



export default function UsersDrawer() {
    const { enqueueSnackbar } = useSnackbar();

    const isUsersDrawerOpen = usersDrawerStore((state) => state.isUsersDrawerOpen)
    const setIsUsersDrawerOpen = usersDrawerStore((state) => state.setIsUsersDrawerOpen)
    const branches = branchesStore((state) => state.branches)

    const [name, setName] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [ownedBranches, setOwnedBranches] = React.useState([])


    const selectedBranch = selectedBranchStore((state) => state.selectedBranch)
    const setPending = pendingStore((state) => state.setPending)


    const getBranchesByOwnerEmail = async (email) => {
        const Ref = query(ref(database, 'branches/'),
            orderByChild("owner"),
            equalTo(email)
        );
        let snapshot = await get(Ref)
        if (snapshot.exists() && isValidObject(snapshot.val())) {
            return Object.entries(snapshot.val())?.map((item) => item[0])
        } else {
            return false
        }

    }

    const handle = async () => {
        if (isUsersDrawerOpen.status === true && isUsersDrawerOpen.type == "update") {
            setEmail(isUsersDrawerOpen.data[1].email || "")
            setPassword(isUsersDrawerOpen.data[1].password || "")
            let a = await getBranchesByOwnerEmail(isUsersDrawerOpen.data[1].email)

            if (a) {
                setOwnedBranches(a)
            } else {
                setOwnedBranches([])
            }

        } else if (isUsersDrawerOpen.status === true && isUsersDrawerOpen.type == "new") {
            setEmail("")
            setPassword("")
            setOwnedBranches([])
        }
    }
    React.useEffect(() => {
        handle()
    }, [isUsersDrawerOpen.status])



    const create = async () => {
        setPending(true)
        try {
            const date = new Date()
console.error("ownedBranches", ownedBranches)
            const updates = {}

            const newUserData = {
                email: email,
                password: password,
                createdAt: date.getTime(),
                ownedBranches: ownedBranches,
            }
            const uid = await push(child(ref(database), 'users/')).key;

            updates[`users/${uid}/`] = newUserData;

            ownedBranches?.forEach(async (id) => {
                updates[`branches/${id}/owner/`] = email;
            })

            await update(ref(database), updates);

            enqueueSnackbar("Kişi Eklendi")
            setIsUsersDrawerOpen({ status: false, type: "new" })
        } catch (err) {
            enqueueSnackbar(`Kişi eklenemedi!`)
            console.error(err)
        }
        setPending(false)
    }

    const updateUser = async () => {
        try {
            setPending(true)
            const updates = {};

            updates[`users/${isUsersDrawerOpen.data[0]}/ownedBranches`] = ownedBranches;

            let deletedFromUserArr = isUsersDrawerOpen?.data[1]?.ownedBranches?.filter((item) => !ownedBranches?.includes(item))

            deletedFromUserArr?.forEach(async (id) => {
                updates[`branches/${id}/owner/`] = null;
            })

            ownedBranches?.forEach(async (id) => {
                updates[`branches/${id}/owner/`] = isUsersDrawerOpen.data[1].email;
            })

            await update(ref(database), updates);


            enqueueSnackbar("Kişi Güncellendi")
            setIsUsersDrawerOpen({ status: false, type: "new" })
            setPending(false)
        } catch (err) {
            console.log("err", err)
            enqueueSnackbar(`Kişi Güncellenemedi`)
        }
    }



    const handleClose = async () => {
        let confirm = await showConfirmationPopup("Kayıt edilmemiş değişiklikleriniz olabilir!")
        if (!confirm) return
        setIsUsersDrawerOpen({ status: false, type: "new" })
    }

    return (
        <div>

            <React.Fragment key={"anchor"}>
                <Fab
                    onClick={() => setIsUsersDrawerOpen({ status: !isUsersDrawerOpen.status, type: "new" })}
                    size="medium"
                    className="fab"
                    color="" aria-label="add"
                >
                    <AddIcon />
                </Fab>
                {/* <Button onClick={() => setIsUsersDrawerOpen({ status: !isUsersDrawerOpen.status, type: "new" })}>{"Yeni Şube Ekle"}</Button> */}
                <Drawer
                    anchor={"right"}
                    open={isUsersDrawerOpen.status}
                    onClose={() => handleClose()}
                >
                    <div className='drawerBody'>
                        <SelectBranchesForUser
                            width={"100%"}
                            val={ownedBranches}
                            set={setOwnedBranches}
                            arr={
                                    isValidArrayAndHasLength(branches) ?
                                    branches
                                    : false
                            }
                        />
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            type="email"
                            value={email}
                            disabled={isUsersDrawerOpen.type === "update"}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Şifre"
                            variant="outlined"
                            type="text"
                            disabled={isUsersDrawerOpen.type === "update"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        {
                            isUsersDrawerOpen.type === "update" ?
                                <button className='drawerButton' onClick={() => updateUser()}>Kaydet</button> :
                                <button className='drawerButton' onClick={() => create()}>Kişi Oluştur</button>
                        }

                    </div>
                </Drawer>
            </React.Fragment>
        </div >
    );
}