import { languageStore, modalStore } from "../../store";

const SelectLanguage = () => {


    const setModal = modalStore((state) => state.setModal)
    const setLanguage = languageStore((state) => state.setLanguage)
    const language = languageStore((state) => state.language)


    const hadnleSelect = (dil) => {
        setLanguage(dil)
        setModal({status: false})
    }


    return (
        <div>
            {
                language === "tr"
                ? <button className="selectLanguageButton" onClick={() => hadnleSelect("en")}>English</button>
                : <button className="selectLanguageButton" onClick={() => hadnleSelect("tr")}>Türkçe</button>
            }
            
        </div>
    )
}

export default SelectLanguage;;