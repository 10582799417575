import { Outlet } from "react-router-dom";
import { adminLeftDrawerStore, branchesDrawerStore, branchesStore, categoryDrawerStore, feedBackStore, pendingStore, productDrawerStore, selectedBranchStore, selectedCategoriesStore, selectedCategoryStore, selectedProductsStore, userStore, } from "./store.js"
import { equalTo, onValue, orderByChild, query, ref } from "firebase/database"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect } from "react"
import Header from "./Header.js"
import CustomDialog from "./CustomDialog.js"
import { auth, database } from "./FirebaseConfig.js";
import "./AdminTemplate.css"
import { Helmet } from "react-helmet";
import LeftSideDrawer from "./LeftSideDrawer.js";
import { isValidAndNotEmptyString, isValidArrayAndHasLength } from "./utils.js";
import {useSwipeable} from "react-swipeable";

const AdminTemplate = () => {
    const pending = pendingStore((state) => state.pending)
    const feedBack = feedBackStore((state) => state.feedBack)
    const setFeedBack = feedBackStore((state) => state.setFeedBack)
    const setUser = userStore((state) => state.setUser)
    const user = userStore((state) => state.user)
    const branches = branchesStore((state) => state.branches)
    const setBranches = branchesStore((state) => state.setBranches)
    const selectedBranch = selectedBranchStore((state) => state.selectedBranch)
    const selectedCategory = selectedCategoryStore((state) => state.selectedCategory)
    
    const selectedCategories = selectedCategoriesStore((state) => state.selectedCategories)
    const setSelectedCategories = selectedCategoriesStore((state) => state.setSelectedCategories)

    const selectedProducts = selectedProductsStore((state) => state.selectedProducts)
    const setSelectedProducts = selectedProductsStore((state) => state.setSelectedProducts)

    const setLeftDrawer = adminLeftDrawerStore((state) => state.setLeftDrawer)
    const leftDrawer = adminLeftDrawerStore((state) => state.leftDrawer)

    const setIsProductDrawerOpen = productDrawerStore(state => state.setIsProductDrawerOpen)
    const isProductDrawerOpen = productDrawerStore(state => state.isProductDrawerOpen)

    const isCategoryDrawerOpen = categoryDrawerStore(state => state.isCategoryDrawerOpen)
    const setIsCategoryDrawerOpen = categoryDrawerStore(state => state.setIsCategoryDrawerOpen)


    const isBranchesDrawerOpen = branchesDrawerStore(state => state.isBranchesDrawerOpen)
    const setIsBranchesDrawerOpen = branchesDrawerStore(state => state.setIsBranchesDrawerOpen)
    
    


    

    useEffect(() => {
        if (user?.isAdmin === true) {
            onValue(ref(database, 'branches/'), (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setBranches(Object.entries(data));
                } else {
                    setBranches({});
                }
            });
        } else {
            const Ref = query(
                ref(database, 'branches/'),
                orderByChild("owner"),
                equalTo(auth?.currentUser?.email),
            );

            onValue(Ref, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setBranches(Object.entries(data));
                } else {
                    setBranches({});
                }
            });
        }
    }, [user])

    const getFeedBack = async () => {
        try {
            let branchUid = selectedBranch[0]
            if (!isValidAndNotEmptyString(branchUid)) throw { code: 1, message: "selectedBranch UID not found" }
            const branchRef = query(
                ref(database, 'feedback/'),
                orderByChild("branch"),
                equalTo(branchUid),
            );
            onValue(branchRef, (snapshot) => {
                if (snapshot.exists()) {
                    const data = Object.entries(snapshot.val());
                    setFeedBack(data);
        console.warn("feedBack", data)

                } else {
                    console.error("no data")
                    setFeedBack([]);
                }
            });
        } catch (err) {
            console.error("err", err)
        }
    }
    useEffect(() => {
        getFeedBack()
    }, [selectedBranch])
    useEffect(() => {
        console.log("branches", branches)
    }, [branches])

   

    useEffect(() => {
        const userRef = query(
            ref(database, 'users/'),
            orderByChild("email"),
            equalTo(auth?.currentUser?.email),
        );
        try {
            onValue(userRef, (snapshot) => {
                if (snapshot.exists()) {
                    console.log("current user data", Object.entries(snapshot.val()))
                    setUser({ ...Object.entries(snapshot.val())[0][1], uid: Object.entries(snapshot.val())[0][0] })
                } else {
                    console.error("no user", auth?.currentUser?.uid)
                    setUser([]);
                }
            });
        } catch (err) {
            console.log("current user data", auth?.currentUser?.uid)
            setUser([])
            console.error("err", err)
        }
    }, [])

    useEffect(() => {
        console.log("feedBack", feedBack)
    }, [feedBack])

    const isOk = (e) => {
        try {
            console.log(e.target.classList.contains("LeftSideDrawer"))
            if(e.target.classList.contains("LeftSideDrawer")) {
                return true
            }else {
                return false
            }
        }catch(err) {
            console.error("swipe err: ", err)
        }
    }

    const handleSwipedUp = (e) => {
        setLeftDrawer({status: true, which: leftDrawer?.which})
    }
 
    const handlers = useSwipeable({
        onSwipedUp: (e) => (isOk(e?.event) && e.dir === "Up" && e.deltaY < -100) ? handleSwipedUp(e) : console.log(e),
        onSwipedDown: (e) => (isOk(e?.event) && e.dir === "Down" && e.deltaY > 100) ? setLeftDrawer({status: false, which: leftDrawer?.which}) : console.log(e),
        // onSwiped: (e) => console.log(e.event.target),
      });

    return (
        <div {...handlers} className={"AdminTemplate"} style={{ width: "100%", height: "100%" }}>
            <Helmet>
                <title>Admin Panel</title>
                {/* <link rel="icon" type="image/png" href={logo} sizes="16x16" /> */}
            </Helmet>
            <LeftSideDrawer />
            <>{
                pending
                    ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: 9999 }}
                        open={pending}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    : null
            }

                <>
                    <Header />
                    <div className="adminOutletWrapper">
                        <Outlet />
                    </div>
                    <CustomDialog />
                </>
            </>
        </div>
    )
}

export default AdminTemplate;