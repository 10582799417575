
export function isValidObject(object) {
    if (
        object !== null &&
        object !== undefined &&
        typeof object === "object" &&
        JSON.stringify(object) !== "[]" &&
        JSON.stringify(object) !== "{}" &&
        JSON.stringify(object)[0] === "{" &&
        JSON.stringify(object)[JSON.stringify(object).length - 1] === "}"
    ) {
        return true
    } else {
        return false
    }

}

export function isValidArray(arr) {
    if (
        arr !== null &&
        arr !== undefined &&
        typeof arr === "object" &&
        JSON.stringify(arr) !== "{}" &&
        JSON.stringify(arr) === "[]"
    ) {
        return true
    } else {
        return false
    }

}

export function isValidArrayAndHasLength(arr) {
    if (
        arr !== null &&
        arr !== undefined &&
        typeof arr === "object" &&
        JSON.stringify(arr) !== "{}" &&
        JSON.stringify(arr)[0] === "[" &&
        JSON.stringify(arr)[JSON.stringify(arr).length - 1] === "]" &&
        arr.length > 0
    ) {
        return true
    } else {
        return false
    }

}


export function isValidAndNotEmptyString(str) {
    if (
        str !== null &&
        str !== undefined &&
        typeof str === "string" &&
        str !== "" &&
        str.trim().length > 0
    ) {
        return true
    } else {
        return false
    }

}