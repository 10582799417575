import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { categoryDrawerStore, pendingStore, productEditDrawerStore, selectedBranchStore, selectedCategoryStore } from './store';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import ImagePicker from './ImagePicker';
import { child, get, onValue, push, ref, set } from 'firebase/database';
import { database, storage } from './FirebaseConfig';
import { uploadBytes, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { useSnackbar } from 'notistack';
import Textarea from '@mui/joy/Textarea';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { showConfirmationPopup } from './API';
import { isValidAndNotEmptyString } from './utils';
import { useSwipeable } from 'react-swipeable';

export default function CategoryDrawer() {
    const { enqueueSnackbar } = useSnackbar();

    const isCategoryDrawerOpen = categoryDrawerStore((state) => state.isCategoryDrawerOpen)
    const setIsCategoryDrawerOpen = categoryDrawerStore((state) => state.setIsCategoryDrawerOpen)

    const [img, setImg] = React.useState(null)
    const [name, setName] = React.useState("")
    const [nameEN, setNameEN] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [descriptionEN, setDescriptionEN] = React.useState("")
    const [logo, setLogo] = React.useState("")

    const selectedBranch = selectedBranchStore((state) => state.selectedBranch)
    const selectedCategory = selectedCategoryStore((state) => state.selectedCategory)
    const setPending = pendingStore((state) => state.setPending)

    React.useEffect(() => {
        if (isCategoryDrawerOpen.status === true && isCategoryDrawerOpen.type == "update") {
            setName(isCategoryDrawerOpen.data[1].name || "")
            setNameEN(isCategoryDrawerOpen.data[1].nameEN || "")
            setDescription(isCategoryDrawerOpen.data[1].description || "")
            setDescriptionEN(isCategoryDrawerOpen.data[1].descriptionEN || "")
            setLogo(isCategoryDrawerOpen?.data[1]?.logo || "")
        } else if (isCategoryDrawerOpen.status === true && isCategoryDrawerOpen.type == "new") {
            setName("")
            setNameEN("")
            setDescription("")
            setLogo("")
            setDescriptionEN("")
        }
    }, [isCategoryDrawerOpen.status])


    const uploadImage = async (file, BranchName, CategoryName) => {
        const imagesRef = storageRef(storage, `categories/${CategoryName}/logo/image`);
        await uploadBytes(imagesRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!', snapshot);
        });
    }

    const createCategoryService = async () => {
        if (Array.isArray(selectedBranch)) {
            setPending(true)
            const date = new Date()
            const uid = await push(child(ref(database), `categories`)).key;
            let url = ""
            if (img) {
                await uploadImage(img, selectedBranch[0], uid)
                url = await getDownloadURL(storageRef(storage, `categories/${uid}/logo/image`))
            } else {
                url = await getDownloadURL(storageRef(storage, `default/default.jpg`))
            }
            await set(ref(database, `categories/${uid}/`), {
                name: name,
                nameEN: nameEN,
                description: description,
                descriptionEN: descriptionEN,
                createdAt: date.getTime(),
                branch: selectedBranch[0],
                logo: url
            })


            enqueueSnackbar("Kategori eklendi")
            setPending(false)
            setIsCategoryDrawerOpen({ status: false, type: "new" })

        } else {
            enqueueSnackbar("Kategori eklenemedi!")
            console.error("createCategoryService err: ")
        }
    }

    const updateCategoryService = async () => {
        if (Array.isArray(selectedBranch)) {
            setPending(true)
            const date = new Date()
            let url = ""
            if (img !== null) {
                await uploadImage(img, selectedBranch[0], isCategoryDrawerOpen.data[0])
                url = await getDownloadURL(storageRef(storage, `categories/${isCategoryDrawerOpen.data[0]}/logo/image`))
            } else if(isValidAndNotEmptyString(logo)) {
                url = logo
            } else {
                url = await getDownloadURL(storageRef(storage, `default/default.jpg`))
            }
            await set(ref(database, `categories/${isCategoryDrawerOpen.data[0]}/`), {
                name: name,
                description: description,
                nameEN: nameEN,
                descriptionEN: descriptionEN,
                createdAt: date.getTime(),
                branch: selectedBranch[0],
                logo: url,
            })

            enqueueSnackbar("Kategori güncellendi")
            setPending(false)
            setIsCategoryDrawerOpen({ status: false, type: "new" })
        } else {
            enqueueSnackbar("Kategori güncellenemedi!")
            console.error("createCategoryService err: ")
        }
    }

    const handleClose = async () => {
        let confirm = await showConfirmationPopup("Kayıt edilmemiş değişiklikleriniz olabilir!")
        if (!confirm) return
        setIsCategoryDrawerOpen({ status: false, type: "new" })
    }


    const handlers = useSwipeable({
        onSwipedRight: (e) => e.deltaX > 100 ? setIsCategoryDrawerOpen({ status: false, type: "new" }) : null,
    });
    return (
        <div>
            <React.Fragment key={"anchor"}>
                <Fab
                    onClick={() => setIsCategoryDrawerOpen({ status: !isCategoryDrawerOpen.status, type: "new" })}
                    size="medium"
                    className="fab"
                    color="" aria-label="add"
                >
                    <AddIcon />
                </Fab>
                {/* <Button onClick={() => setIsCategoryDrawerOpen({ status: !isCategoryDrawerOpen.status, type: "new" })}>Yeni Kategori Ekle</Button> */}
                <Drawer
                {...handlers}
                    anchor={"right"}
                    open={isCategoryDrawerOpen.status}
                    onClose={() => handleClose()}
                >
                    <div className='drawerBody'>
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Kategori Başlık"
                            variant="outlined"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Kategori Başlık İngilizce"
                            variant="outlined"
                            type="text"
                            value={nameEN}
                            onChange={(e) => setNameEN(e.target.value)}
                        />
                        <Textarea
                            placeholder="Kategori Açıklama"
                            style={{ width: "100%", height: "auto" }}
                            value={description}
                            minRows={4}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <Textarea
                            placeholder="Ürün Açıklama ingilizce"
                            style={{ width: "100%", height: "auto" }}
                            value={descriptionEN}
                            minRows={4}
                            onChange={(e) => setDescriptionEN(e.target.value)}
                        />

                        <ImagePicker val={img} set={setImg} defaultValue={logo} setDefaultValue={setLogo} customStyle={{ width: "100%", height: 150 }} />
                        {
                            isCategoryDrawerOpen.type == "new"
                                ? <button className='drawerButton' onClick={() => createCategoryService()} variant="outlined">Yeni kategori ekle</button>
                                : <button className='drawerButton' onClick={() => updateCategoryService()} variant="outlined">Kaydet</button>

                        }
                    </div>
                </Drawer>
            </React.Fragment>
        </div >
    );
}