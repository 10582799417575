import React, { useEffect, useState } from "react"
import CustomCard from "./CustomCard.js"
import Pagination from '@mui/material/Pagination'
import Box from '@mui/material/Box';
import { branchesStore, categoriesStore, pendingStore, productsStore, selectedBranchStore, selectedCategoriesStore, selectedCategoryStore } from "./store.js";
import { push, ref, set } from "firebase/database";
import { database, storage } from "./FirebaseConfig.js";
import CurrentBranchesDialog from "./CurrentBranchesDialog.js";
import { uploadBytes } from "firebase/storage";
import CategoryDrawer from "./CategoryDrawer.js";
import CustomCategoryCard from "./CustomCategoryCard.js";
import { isValidAndNotEmptyString, isValidArrayAndHasLength } from "./utils.js";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import "./BranchesComponent.css"
import { getItemStyle, getListStyle, reorder } from "./API.js";
import CustomFilterButton from "./Components/CustomFilterButton.js";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ProductComponent from "./ProductComponent.js";
import Collapse from '@mui/material/Collapse';


const CategoriesComponent = () => {

    const branches = branchesStore((state) => state.branches)
    const setBranches = branchesStore((state) => state.setBranches)

    const [filter, setFilter] = useState(true)

    const categories = categoriesStore((state) => state.categories)
    const selectedBranch = selectedBranchStore((state) => state.selectedBranch)


    const selectedCategories = selectedCategoriesStore((state) => state.selectedCategories)
    const setSelectedCategories = selectedCategoriesStore((state) => state.setSelectedCategories)


    const [page, setPage] = useState(1)

    const setPending = pendingStore((state) => state.setPending)
    const pending = pendingStore((state) => state.pending)


    const setCategories = categoriesStore(state => state.setCategories)
    const products = productsStore(state => state.products)
    const setProducts = productsStore(state => state.setProducts)

    const style = {
        boxStyle: {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
            minHeight: 350,

        },
        boxStyleCol: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
        }
    }




    useEffect(() => {
        if (filter === false) {
            setPage(1)
        }
    }, [filter])


    const onDragEnd = async (result) => {
        // dropped outside the list
        if (!result.destination) {
            console.log("Drop outside the list", result)
            return;
        }
        let startIndex = result.source.index
        let endIndex = result.destination.index
        let itemId = result.draggableId


        if (isValidArrayAndHasLength(selectedBranch) && isValidArrayAndHasLength(selectedCategories)) {
            let a = selectedCategories.filter((item) => item[1].branch === selectedBranch[0]).sort((a, b) => { return a[1]?.order - b[1]?.order })
            const items = await reorder(
                a,
                (10 * (page - 1)) + result.source.index,
                (10 * (page - 1)) + result.destination.index
            );

            await items.forEach(async (item, index) => {
                if (isValidAndNotEmptyString(item[0])) {
                    await set(ref(database, `categories/${item[0]}/order`), index)
                }
            })
        }
    }

    const [expanded, setExpanded] = useState(false)

    return (
        <Box style={style.boxStyleCol}>

            <div className={"tableHead"}>
                <h2 className="section-title">Kategoriler</h2>
                <CategoryDrawer />
            </div>

            <Box style={style.boxStyle}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => {
                            return (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >

                                    {
                                        isValidArrayAndHasLength(selectedBranch) && isValidArrayAndHasLength(selectedCategories) ?
                                            selectedCategories?.filter((item) => item[1].branch === selectedBranch[0]).sort((a, b) => { return a[1]?.order - b[1]?.order }).filter((item, i) => !filter || i < 10 * page && i >= 10 * (page - 1)).map((item, index) => (
                                                <Draggable className="categoryAndProductsWrapper" isDragDisabled={expanded !== false} key={item[0]} draggableId={item[0]} index={index}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    ...getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )
                                                                }}
                                                                className="categoryAndProductsWrapper"
                                                            >
                                                                <CustomCategoryCard disabled={expanded !== false} key={item[0]} data={item} />
                                                            </div>

                                                        )
                                                    }}
                                                </Draggable>
                                            ))
                                            : null
                                    }


                                    {provided.placeholder}
                                </div>
                            )
                        }}
                    </Droppable>
                </DragDropContext>


                {
                    isValidArrayAndHasLength(selectedCategories) ?
                        <div className="paginationRowWrapper">
                            <Pagination className="pagination" count={Math.ceil(filter ? selectedCategories?.length / 10 : 1)} onChange={(e, val) => setPage(val)} page={page} variant="outlined" />
                            <CustomFilterButton style={{ width: 250, height: 35 }} set={setFilter} val={filter} labels={["Hepsi", "Tek Sayfa"]} />
                        </div>
                        : null
                }


            </Box>

        </Box>


    )
}

export default CategoriesComponent