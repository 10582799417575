import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// process.env.REACT_APP_API_KEY
const firebaseConfig = {
    apiKey: "AIzaSyD_xIX2j_RvQ-zrwk6JOo1OaV8ZHfFEI2w",
    authDomain: "mizanplascom.firebaseapp.com",
    databaseURL: "https://mizanplascom-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "mizanplascom",
    storageBucket: "mizanplascom.appspot.com",
    messagingSenderId: "1084767578832",
    appId: "1:1084767578832:web:f9017be94b3be7c8d80cd5",
    measurementId: "G-6R83YSLJMP"
  };

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const database = getDatabase(app);
export const storage = getStorage(app);

