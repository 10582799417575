import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { branchesStore, usersDrawerStore } from '../../store';
import { isValidAndNotEmptyString, isValidArrayAndHasLength, isValidObject } from '../../utils';
import { enqueueSnackbar } from 'notistack';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function SelectBranchesForUser(props) {

    const {width, val, set,arr} = props;
    const branches = branchesStore((state) => state.branches)
    const [availableBranchesArr, setAvailableBranchesArr] = React.useState([]);
    const isUsersDrawerOpen = usersDrawerStore((state) => state.isUsersDrawerOpen)

    React.useEffect(() => {
        let a = []
        if(isValidArrayAndHasLength(branches)) {
            a = branches
        }
        let b = a.filter((item) => !isValidAndNotEmptyString(item[1]?.owner))
        setAvailableBranchesArr(b)
    }, [branches])


  const [error, setError] = React.useState(false);

  const handleChange = (id) => {
    if(val?.includes(id)) {
        set(val?.filter((item) => item !== id))
    } else {
        if(!availableBranchesArr.map((item) => item[0]).includes(id) && (isUsersDrawerOpen.type === "new" || !isUsersDrawerOpen.data[1].ownedBranches.includes(id))) {
          enqueueSnackbar("Bu branch başka birine ait!")  
          console.error("bu branch zaten birine ait")
        }else {
            set([...val, id])
        }
    }
  };

  return (
      <FormControl style={{width: width}}>
        <InputLabel 
          error={error}
          id="demo-multiple-checkbox-label">Tag</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={arr ? arr?.filter((item) => val?.includes(item[0])).map((item) => item[1]?.name) : []}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          error={error}
        >
          {arr && arr?.map((item) => (
            <MenuItem onClick={() => handleChange(item[0])} key={item[0]} value={item[1]?.name}>
              <Checkbox checked={val?.includes(item[0])} />
              <ListItemText primary={item[1]?.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}