import { equalTo, get, onValue, orderByChild, query, ref, set } from 'firebase/database';
import './App.css';
import { auth, database } from "./FirebaseConfig"
import {
  BrowserRouter as Router,
  Route,
  Link,
  createBrowserRouter,
  RouterProvider,
  useNavigate,
  BrowserRouter,
  Routes,
  redirect,
  Navigate,
  useLocation
} from "react-router-dom";
import Dasboard from './Dashboard.js';
import Login from './Login.js';
import { onAuthStateChanged } from 'firebase/auth';
import { Suspense, useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from './Menu.js';
import tr from "../src/MenuSide/Components/locale/tr.json"
import en from "../src/MenuSide/Components/locale/en.json"
import MenuHome from './MenuSide/MenuHome.js';
import ProductPage from './MenuSide/ProductPage.js';
import MenuTemplate from './MenuSide/MenuTemplate.js';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { t } from 'i18next';
import { branchesStore, categoriesStore, languageStore, productsStore, selectedBranchStore, selectedCategoriesStore, selectedCategoryStore, selectedProductStore, selectedProductsStore } from './store.js';
import MenuLogin from './MenuSide/MenuLogin.js';
import SearchPage from './MenuSide/SearchPage.js';
import { isValidAndNotEmptyString, isValidArrayAndHasLength, isValidObject } from './utils.js';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Feedback from './MenuSide/Feedback.js';
import Messages from './Messages.js';
import AdminTemplate from './AdminTemplate.js';
import ProfileSettings from './AdminSide/ProfileSettings.js';

function App() {

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
  } else {
    console.log = () => { }
    console.warn = () => { }
  }



  const [isUserAuth, setIsUserAuth] = useState(false)
  const [pending, setPending] = useState(true)
  const language = languageStore((state) => state.language)
  const branches = branchesStore((state) => state.branches)
  const setBranches = branchesStore((state) => state.setBranches)

  const setLanguage = languageStore(state => state.setLanguage)


  const categories = categoriesStore(state => state.categories)
  const setCategories = categoriesStore(state => state.setCategories)
  const products = productsStore(state => state.products)
  const setProducts = productsStore(state => state.setProducts)
  const selectedBranch = selectedBranchStore(state => state.selectedBranch)
  const setSelectedBranch = selectedBranchStore(state => state.setSelectedBranch)
  const setSelectedCategory = selectedCategoryStore(state => state.setSelectedCategory)
  const selectedCategory = selectedCategoryStore(state => state.selectedCategory)
  const setSelectedCategories = selectedCategoriesStore(state => state.setSelectedCategories)
  const setSelectedProducts = selectedProductsStore(state => state.setSelectedProducts)








  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: {
          translation: en
        },
        tr: { translation: tr }
      },
      lng: language,
      fallbackLng: "tr",

      interpolation: {
        escapeValue: false
      }
    });

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("current user: ", user)
        setIsUserAuth(true)
        localStorage.setItem("isAllReadyUserLoggedIn", true)

        const Ref = query(
          ref(database, 'users/'),
          orderByChild("email"),
          equalTo(auth?.currentUser?.email),
        )

        const snapshot = await get(Ref)
        if (snapshot.exists()) {
          const userDataFromDatabase = Object.entries(snapshot.val())[0]
          await set(ref(database, `users/${userDataFromDatabase[0]}/isLoggedIn`), true)
        }

      } else {
        setIsUserAuth(false)
        localStorage.setItem("isAllReadyUserLoggedIn", false)

        console.log("asdas")
        setSelectedBranch({})
      }
      setPending(false)
    });
  }, [])

  // useEffect(() => {
  //   console.warn("products", products)
  // }, [products])

  // useEffect(() => {
  //   console.warn("categories", categories)
  // }, [categories])

  useEffect(() => {
  }, [selectedBranch])

  // const add = async () => {
  //   let a = []
  //   if (!isValidArrayAndHasLength(categories)) return
  //   categories?.forEach(async (item) => {
  //     let url = await get(ref(database, `categories/${item[0]}/logo`))
  //     console.log("get")

  //     if (url.val() === "https://firebasestorage.googleapis.com/v0/b/mizanplascom.appspot.com/o/default%2Fdefault.jpg?alt=media&token=6ab29be0-dd7a-403c-bd18-9da53504b9d4") {
  //       a.push(item)
  //       console.log("push")
  //       await set(ref(database, `categories/${item[0]}/logo`), "https://firebasestorage.googleapis.com/v0/b/mizanplascom.appspot.com/o/default%2Fdefault.jpg?alt=media&token=b0f1f905-d67e-4bad-b10d-51b5c169f948")

  //     }
  //   })

     
  // }


  // useEffect(() => {
  //   onValue(ref(database, 'branches/'), (snapshot) => {
  //     if (snapshot.exists()) {
  //       const data = snapshot.val();
  //       setBranches(data);
  //     } else {
  //       setBranches({});
  //     }
  //   });
  // }, [])


  useEffect(() => {
    try {
      let uid = selectedBranch[0]
      if (!isValidAndNotEmptyString(uid)) throw { code: 1, message: "selectedBranch UID not found" }
      const Ref = query(
        ref(database, 'categories/'),
        orderByChild("branch"),
        equalTo(uid),
      );
      onValue(Ref, (snapshot) => {
        if (snapshot.exists()) {
          const data = Object.entries(snapshot.val());
          setCategories(data)
        } else {
          console.error("no data")
          setCategories([]);
        }
      });
    } catch (err) {
      console.error("err", err)
    }


    try {
      let uid = selectedBranch[0]
      if (!isValidAndNotEmptyString(uid)) throw { code: 1, message: "selectedBranch UID not found" }

      const Ref = query(
        ref(database, 'products/'),
        orderByChild("branch"),
        equalTo(uid),
      );
      onValue(Ref, (snapshot) => {
        if (snapshot.exists()) {
          const data = Object.entries(snapshot.val());
          setProducts(data)
        } else {
          console.error("no data")
          setProducts([]);
        }
      });
    } catch (err) {
      console.error("err", err)
    }
  }, [selectedBranch])


  useEffect(() => {
    try {
      let branchUid = selectedBranch[0]
      if (!isValidAndNotEmptyString(branchUid)) throw { code: 1, message: "selectedBranch UID not found" }
      const Ref = query(
        ref(database, 'categories/'),
        orderByChild("branch"),
        equalTo(branchUid),
      );
      console.error(branchUid)
      onValue(Ref, (snapshot) => {
        if (snapshot.exists()) {
          const data = Object.entries(snapshot.val());
          setSelectedCategories(data);
        } else {
          console.error("no data")
          setSelectedCategories([]);
        }
      });
    } catch (err) {
      console.error("err", err)
    }
  }, [selectedBranch])

  useEffect(() => {
    try {
      let uid = selectedCategory[0]
      if (!isValidAndNotEmptyString(uid)) throw { code: 1, message: "selectedBranch UID not found" }
      const Ref = query(
        ref(database, 'products/'),
        orderByChild("category"),
        equalTo(uid),
      );
      onValue(Ref, (snapshot) => {
        if (snapshot.exists()) {
          const data = Object.entries(snapshot.val());
          setSelectedProducts(data);
        } else {
          console.error("no data")
          setSelectedProducts([]);
        }
      });
    } catch (err) {
      console.error("err", err)
    }
  }, [selectedCategory])

  return (

    <Routes>

      <Route exact path="/admin"
        element={
          !pending && isUserAuth
            ? <AdminTemplate />
            : pending
              ? <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={pending}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              : <Login />
        }
      >
        <Route index element={<Dasboard />} />
        <Route path="messages" element={<Messages />} />
        <Route path="profile-settings" element={<ProfileSettings />} />
      </Route>
      {/* <Route path="/:branchName" element={isValidObject(selectedBranchForMenu) && isValidArrayAndHasLength(Object.entries(selectedBranchForMenu)) ? <MenuTemplate /> : <Navigate to="/" /> }> */}
      <Route path="/:branchName" element={<MenuTemplate />}>
        <Route index element={<MenuLogin />} />
        <Route path='feedback' element={<Feedback />} />
        <Route path='menu' element={<MenuHome />} />
        <Route path='search' element={<SearchPage />} />
        <Route path="menu/:productId" element={<ProductPage />} />
      </Route>

    </Routes>
  );
}

export default App;
