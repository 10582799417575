import MessageRow from "./MessageRow";
import { feedBackStore } from "./store";
import { isValidArrayAndHasLength } from "./utils";
import "./Messages.css"
import Pagination from '@mui/material/Pagination';
import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

const Messages = () => {
    const feedBack = feedBackStore((state) => state.feedBack)
    const [page, setPage] = useState(1)
    const [filter, setFilter] = useState(false)

    useEffect(() => {
        if (isValidArrayAndHasLength(feedBack)) {
            let pageCount = Math.ceil(feedBack?.filter((item) => filter ? item[1]?.readed !== true : true)?.length / 4)
            if (filter === true && page > pageCount) {
                setPage(1)
            }
        }
    }, [filter])

    return (
        <div className={"MessagesWrapper"}>

            <div className={"messageList"}>
                <div className={"filterButtonsWrapper"}>
                    <button className={filter ? "filterButton" : "filterButton active"} variant={filter ? "text" : "outlined"} onClick={() => setFilter(false)} sx={{ width: "50%" }}>Hepsini Göster</button>
                    <button className={!filter ? "filterButton" : "filterButton active"} variant={filter ? "outlined" : "text"} onClick={() => setFilter(true)} sx={{ width: "50%" }}>Sadece Okunmamışlar</button>
                </div>
                {
                    isValidArrayAndHasLength(feedBack) && feedBack?.filter((item) => filter ? item[1]?.readed !== true : true).filter((_, i) => i < 4 * page && i >= 4 * (page - 1)).map((item) => {
                        return <MessageRow key={item[0]} messageData={item} />
                    })
                }
                {
                    isValidArrayAndHasLength(feedBack) ?
                        <Pagination
                            onChange={(_, val) => setPage(val)}
                            page={page}
                            count={Math.ceil(feedBack?.filter((item) => filter ? item[1]?.readed !== true : true)?.length / 4)}
                             />
                        : null
                }

            </div>
        </div>
    )
}

export default Messages;