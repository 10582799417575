import { useEffect, useState } from "react"
import "./ProductRow.css"
import { isValidArrayAndHasLength } from "../utils"
import { languageStore } from "../store"
import { tagsArrWithSvg } from "./Components/utils"
import Tooltip from '@mui/material/Tooltip';



const ProdutRow = (props) => {
    const { item } = props

    const [priceArr, setPriceArr] = useState([])
    const language = languageStore((state) => state.language)

    function findLargestTwoNumbers(arr) {
        // Diziyi küçükten büyüğe doğru sırala
        arr.sort(function (a, b) {
            return b - a; // Büyükten küçüğe sıralama
        });

        // En büyük iki sayıyı al
        var largestTwoNumbers = [arr[0], arr[arr.length - 1]];

        return largestTwoNumbers;
    }
   
    useEffect(() => {
        if (isValidArrayAndHasLength(item[1].options) && item[1].options.length > 1) {
            // setPriceArr(findLargestTwoNumbers([...item[1].options.map((item) => item.price), item[1]?.price]))
            setPriceArr([...item[1].options.map((item) => item.price)])
        }
    }, [])


    const formatter = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2
    });

    return (
        <a href={"menu/" + item[0]} className="ownedProdcutRow">
            <div className="first">
                <div className="left">
                    <p className="title">{language === "tr" ? item[1].name : item[1]?.nameEN}</p>
                    <p className="description">{language === "tr" ? item[1].description : item[1]?.descriptionEN}</p>
                    <p className="price">{
                        isValidArrayAndHasLength(item[1].options) && item[1].options.length > 1 ?
                        priceArr.map((item, index) => index != (priceArr.length - 1) ? formatter.format(item) + " - " : formatter.format(item) )
                            // formatter.format(priceArr[1]) + " - " + formatter.format(priceArr[0])
                            : formatter.format(item[1].price)
                    }</p>
                </div>
                <div className="right">
                    <img src={item[1].logo} />
                </div>
            </div>
            <div className="second">
                {
                    isValidArrayAndHasLength(item[1].tags) ?
                        tagsArrWithSvg.filter((a) => item[1].tags.map(item => item.toLocaleUpperCase("tr")).includes(a.name.toLocaleUpperCase("tr"))).map((item) => {
                            return <Tooltip title={item.name} placement="top">
                                {item.svg}
                            </Tooltip>

                        })
                        : null
                }
            </div>
        </a>
    )
}

export default ProdutRow;