import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { database } from './FirebaseConfig';
import Skeleton from '@mui/material/Skeleton';
import { dialogStore, pendingStore, productDrawerStore, selectedBranchStore, selectedCategoryStore, selectedProductStore } from './store';
import { isValidAndNotEmptyString, isValidArrayAndHasLength } from './utils';
import "./ProductCard.css"
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { get, onValue, ref, remove } from 'firebase/database';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { useNavigate } from "react-router-dom"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import AdsClickIcon from '@mui/icons-material/AdsClick';



export default function CustomProductCard(props) {
    const { data } = props;


    const navigate = useNavigate()

    const selectedBranch = selectedBranchStore((state) => state.selectedBranch)


    const setPending = pendingStore((state) => state.setPending)

    const setSelectedProduct = selectedProductStore(state => state.setSelectedProduct)

    const setIsProductDrawerOpen = productDrawerStore(state => state.setIsProductDrawerOpen)

    const setDialog = dialogStore((state) => state.setDialog)

    const [imageError, setImageError] = React.useState(false);
    const [viewCount, setViewCount] = React.useState(0);
    const viewCountChipRef = React.useRef(null)

    // React.useEffect(() => {
    //     if (viewCountChipRef.current.style) {
    //         console.log(viewCountChipRef)
    //         viewCountChipRef.current.style.color = "rgb(88, 218, 127)"
    //         const timeout = setTimeout(() => {
    //             viewCountChipRef.current.style.color = "rgba(0, 0, 0, 0.87)"
    //             clearTimeout(timeout)
    //         }, 1000)
    //     }

    // }, [viewCount])
    const aylar = [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık"
    ];

    let date = new Date(data[1].createdAt)
    // console.log(data[1]?.branchName, `${data[1]?.branchLogo}`)


    const showConfirmationPopup = () => {
        return new Promise((resolve) => {
            setDialog({ status: true, title: "Dikkat", message: `"${selectedBranch[1].name}" kategorinize ait "${data[1].name}" isimli ürününüzü silmek üzeresiniz.`, resolve: resolve })
        });
    };

    const handleDeleteProduct = async () => {
        const userConfirmed = await showConfirmationPopup();

        if (userConfirmed) {
            setPending(true)
            console.error("silindi", data[0])
            try {
                await remove(ref(database, `products/${data[0]}`))
            } catch (err) {
                console.error("handleDeleteCategory error: ", err)
            }
            setPending(false)
        }
    }

    React.useEffect(() => {
        try {
            onValue(ref(database, 'analysis/' + `products/${data[0]}`), (snapshot) => {
                if (snapshot.exists()) {
                    setViewCount(snapshot.val()?.count)
                } else {
                    setViewCount(0)
                }
            });
        } catch (err) {
            console.error(err)
        }
    }, [])

    const options = [
        <IconButton onClick={() => setIsProductDrawerOpen({ status: true, type: "update", data: data })} aria-label="delete">
            <ModeEditIcon />
            <Typography ml={2} fontSize={18} variant="p" component="p">
                Güncelle
            </Typography>
        </IconButton>,
        <IconButton onClick={() => navigate(`/${selectedBranch[1]?.name}/menu/${data[1]?.name}`)} aria-label="preview">
            <VisibilityRoundedIcon />
            <Typography ml={2} fontSize={18} variant="p" component="p">
                Göz At
            </Typography>
        </IconButton>,
        <IconButton onClick={() => handleDeleteProduct()} aria-label="delete">
            <DeleteIcon />
            <Typography ml={2} fontSize={18} variant="p" component="p">
                Sil
            </Typography>
        </IconButton>
    ];

    const ITEM_HEIGHT = 48;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const formatter = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2
    });


    return (
        <div className='productCardWrapper'>
            <Card
                onClick={() => setSelectedProduct(data)}
                className={"cardRow"}
                style={{
                    width: "100%",
                    height: 50,
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                sx={[
                    {
                        '&:hover': {
                            transition: ".2s",
                            backgroundColor: "#1976d240"
                        },
                    },

                ]}>

                <div className='row-first-col'>
                    {
                        isValidArrayAndHasLength(data) && isValidAndNotEmptyString(data[1]?.logo) && !imageError
                            ? <img
                                src={data[1]?.logo}
                                onError={() => setImageError(true)}
                            />
                            : <Skeleton variant="rounded" width={150} height={150} />
                    }
                    <div className='infoWrapper'>
                        <h2>{data[1].name}</h2>
                        <h3>{aylar[date.getMonth()]} {date.getDate()}, {date.getFullYear()}</h3>
                    </div>
                    <Chip ref={viewCountChipRef} className='viewCountChip' icon={<AdsClickIcon />} label={viewCount} variant="outlined" />
                </div>

                <div className='buttonsWrapper'>
                    {
                        window.innerWidth > 1000 ?
                            <>
                            {formatter.format(data[1].price)}
                                <IconButton onClick={() => setIsProductDrawerOpen({ status: true, type: "update", data: data })} aria-label="delete">
                                    <ModeEditIcon />
                                </IconButton>
                                <IconButton onClick={() => navigate(`/${selectedBranch[1]?.name}/menu/${data[0]}`)} aria-label="preview">
                                    <VisibilityRoundedIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteProduct()} aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </>
                            : <div>
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    style={{ flexDirection: "row" }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            width: '20ch',
                                        },
                                    }}
                                >
                                    {options.map((option) => (
                                        <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                    }
                </div>
                {/* <p>{data[1].description}</p> */}


            </Card>
        </div>
    );
}