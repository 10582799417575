import { branchesStore, categoriesStore, filterTagsStore, languageStore, modalStore, productsStore, selectedBranchStore, selectedCategoriesStore, selectedProductStore, selectedProductsStore } from "../store"
import { isValidArrayAndHasLength, isValidObject } from "../utils"
import { tagsArrWithSvg } from "./Components/utils";
import "./MenuHome.css"
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useRef, useState } from "react";
import ProdutRow from "./ProductRow";

const MenuHome = () => {

    const filterSvg = <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="w-6 h-6 text-primary cursor-pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 14.5c-1.58 0-2.903 1.06-3.337 2.5H2v2h2.163c.434 1.44 1.757 2.5 3.337 2.5s2.903-1.06 3.337-2.5H22v-2H10.837c-.434-1.44-1.757-2.5-3.337-2.5zm0 5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5S9 17.173 9 18s-.673 1.5-1.5 1.5zm9-11c-1.58 0-2.903 1.06-3.337 2.5H2v2h11.163c.434 1.44 1.757 2.5 3.337 2.5s2.903-1.06 3.337-2.5H22v-2h-2.163c-.434-1.44-1.757-2.5-3.337-2.5zm0 5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5z"></path><path d="M12.837 5C12.403 3.56 11.08 2.5 9.5 2.5S6.597 3.56 6.163 5H2v2h4.163C6.597 8.44 7.92 9.5 9.5 9.5s2.903-1.06 3.337-2.5h9.288V5h-9.288zM9.5 7.5C8.673 7.5 8 6.827 8 6s.673-1.5 1.5-1.5S11 5.173 11 6s-.673 1.5-1.5 1.5z"></path></svg>
    const searchSvg = <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-secondary h-5 w-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path></svg>
    const hideWrapperRef = useRef("")

    const filterTags = filterTagsStore(state => state.filterTags)

    const language = languageStore(state => state.language)

    const selectedBranch = selectedBranchStore(state => state.selectedBranch)
    const selectedCategories = selectedCategoriesStore(state => state.selectedCategories)
    const selectedProducts = selectedProductsStore(state => state.selectedProducts)
    const products = productsStore(state => state.products)

    const categories = categoriesStore(state => state.categories)

    const [brandName, setBrandName] = useState("")
    const [brandSlogan, setBrandSlogan] = useState("")

    const [aciveCategory, setActiveCategory] = useState("")
    const [pending, setPending] = useState(true)
    const [scrolling, setScrolling] = useState(false)

    // console.log("branches", branches)

    const setModal = modalStore(state => state.setModal)
    function checkVisible(elm) {
        var rect = elm.getBoundingClientRect();
        var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }

    useEffect(() => {
        let a = document.querySelector(".categoriesScrollWrapper")
        let left = document.querySelector("#section-filter > div.categoriesWrapper > button.left")
        let right = document.querySelector("#section-filter > div.categoriesWrapper > button.right")
        const handleScroll = () => {
            console.log(a.scrollWidth, a.scrollLeft, a.scrollLeft > 90)
            if (a.scrollLeft < 90) {
                left.setAttribute("disable", true)
            } 
            if(a.scrollLeft > a.scrollWidth - a.offsetWidth - 90) {
                right.setAttribute("disable", true)
            }
            if(a.scrollLeft < a.scrollWidth - a.offsetWidth - 90) {
                right.setAttribute("disable", false)
            }
            if(a.scrollLeft > 90) {
                left.setAttribute("disable", false)
            }
        };
        a.addEventListener('scroll', handleScroll);

        return () => {
            a.removeEventListener('scroll', handleScroll);
        };
    }, [])
    useEffect(() => {
        let a = document.querySelectorAll(".categoryAndOwnProducts")
        const handleScroll = () => {
            const b = []
            // a?.forEach((item) => {
            //     if (checkVisible(item) == true) {
            //         b.push(item)
            //     }
            // })

            let c = b[b.length - 1]?.getAttribute("id") || "";
            //    if(!scrolling) {
            //     if(aciveCategory !== c || aciveCategory === "") {
            //         setActiveCategory(c)
            //         let d = document.querySelector(`#mini-${c}`)
            //      if(d) {
            //         d?.scrollIntoView({behavior: "smooth" ,block: "start", inline: "start" });
            //         setScrolling(true)
            //      }
            //     }

            //    }

            if (window.scrollY > 240) {
                hideWrapperRef.current.style.cssText = "animation: mini-categoriesWrapper .2s linear forwards"
            } else {
                hideWrapperRef.current.style.cssText = "animation: mini-categoriesWrapper-close .2s linear forwards"
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Component unmount edildiğinde event listener'ı temizleme
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [aciveCategory, scrolling]);




    const rightArrowSvg = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7"></path></svg>

    return (
        <div className={"MenuHomeWrapper"}>
            <div ref={hideWrapperRef} className="mini-categoriesWrapper">
                {
                    isValidArrayAndHasLength(categories) ? categories?.map((item) => {
                        return <a
                            id={"mini-" + item[0]}
                            onClick={
                                () => {
                                    document.querySelector(`#${item[0]}`)?.scrollIntoView({
                                        behavior: "smooth", block: "start", inline: "nearest"
                                    })
                                    setActiveCategory(item[0])
                                }
                            }
                            className={aciveCategory == item[0] ? "active" : null}
                            key={item[0]}
                        >
                            <label>{language === "tr" ? item[1].name : item[1].nameEN}</label>
                        </a>
                    }) : [1, 2, 3, 4, 5].map((item) => {
                        return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Skeleton variant="rounded" width={104} style={{ minWidth: 104 }} height={10} />
                        </div>
                    })
                }
            </div>
            <section id="section-filter">
                <div className="titleWrapper">

                    {
                        !isValidArrayAndHasLength(selectedBranch) ?
                            <>
                                <Skeleton variant="rounded" width={150} style={{ marginBottom: 5 }} height={28} />
                                <Skeleton variant="rounded" width={250} style={{ minWidth: 104 }} height={23} />
                            </> :
                            <>
                                <h2>{selectedBranch[1]?.name.toLocaleUpperCase("tr")}</h2>
                                <h3>{selectedBranch[1]?.slogan.toLocaleUpperCase("tr")}</h3>
                            </>

                    }
                </div>

                <div className="filterAndSvgWrapper">
                    <a href="search" className="fakeSearchInput">
                        {searchSvg}
                        <span>Arama</span>
                    </a>

                    <button onClick={() => setModal({ status: true, type: "filter-tags" })}>
                        {filterSvg}
                    </button>
                </div>

                <div className="categoriesWrapper">

                    <button className="left" onClick={() => document.querySelector(".categoriesScrollWrapper").scrollLeft = document.querySelector(".categoriesScrollWrapper").scrollWidth ? -document.querySelector(".categoriesScrollWrapper").scrollWidth : 0}>
                        {rightArrowSvg}
                    </button>
                    <div className="categoriesScrollWrapper">
                        {
                            isValidArrayAndHasLength(selectedCategories) ? selectedCategories.map((item) => {
                                return <a onClick={() => document.querySelector(`#${item[0]}`)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })}
                                    key={item[0]}
                                    className="categoryBox"
                                >
                                    <div className="imgWrapper">
                                        <img src={item[1].logo} />
                                    </div>
                                    <label>{language === "tr" ? item[1].name : item[1].nameEN}</label>
                                </a>
                            }) : [1, 2, 3, 4, 5].map((item) => {
                                return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <Skeleton variant="rounded" width={104} style={{ minWidth: 104, marginBottom: 5 }} height={78} />
                                    <Skeleton variant="rounded" width={104} style={{ minWidth: 104 }} height={10} />
                                </div>
                            })
                        }
                    </div>
                    <button className="right" onClick={() => document.querySelector(".categoriesScrollWrapper").scrollLeft = document.querySelector(".categoriesScrollWrapper").scrollWidth}>
                        {rightArrowSvg}
                    </button>

                </div>


            </section>
            <div className="productsWrapper">
                {
                    isValidArrayAndHasLength(selectedCategories) ? selectedCategories.sort((a,b) => {return a[1]?.order - b[1]?.order}).map((category) => {
                        return <div key={category[0]} id={category[0]} className="categoryAndOwnProducts">
                            <div className="categoryNameAndImageWrapper">
                                <div className="imageWrapper">
                                    <img className="categoryImage" src={category[1].logo}></img>
                                </div>

                                <p className="categoryTitle">{
                                    language === "tr" ? category[1].name : category[1].nameEN
                                }</p>
                                <p className="categoryDescp">{language === "tr" ? category[1]?.description : category[1]?.descriptionEN}</p>
                            </div>

                            {
                                isValidArrayAndHasLength(products) &&
                                products.filter((item) => item[1].category === category[0]).sort((a,b) => {return a[1]?.order - b[1]?.order}).filter((item) => isValidArrayAndHasLength(filterTags) ?
                                    item[1]?.tags?.some((a) => filterTags.includes(a)
                                    ) : true).map((item) => {
                                        return <ProdutRow item={item} />
                                    })
                            }
                        </div>
                    }) : null
                }
            </div>
            {
               isValidArrayAndHasLength(selectedBranch) && selectedBranch[1]?.warningText ?
                    <div className="menuWarningBox">
                        <p>
                            {language === "tr" ? selectedBranch[1]?.warningText : selectedBranch[1]?.warningTextEN}
                        </p>
                    </div> : null
            }
        </div>
    )
}

export default MenuHome;