import { t } from "i18next";
import { filterTagsStore, languageStore, modalStore } from "../../store";
import "./Modal.css"
import SelectLanguage from "./SelectLanguage";
import FilterTags from "./FilterTags";
import Info from "./Info";





const Modal = () => {


    const modal = modalStore(state => state.modal)
    const setModal = modalStore(state => state.setModal)
    const language = languageStore(state => state.language)
    const setLanguage = languageStore(state => state.setLanguage)
    const setFilterTags = filterTagsStore(state => state.setFilterTags)


    const types = [
        { type: "select-language", title: t("selectLanguage"), body: <SelectLanguage /> },
        { type: "filter-tags", title: t("filter-popup-title"), body: <FilterTags /> },
        { type: "info", title: t("info-popup-title"), body: <Info /> }
    ]



    const closeSvg = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-5 w-5 cursor-pointer text-primary"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>


    return (
        <div className={modal?.status === true ? "ModalWrapper open" : "ModalWrapper"}>
            <div className="ModalBox" style={{animation: modal?.status === true ? "modal-mobil-open .2s linear forwards" : "modal-mobil-close .2s linear forwards"}}>
                <div className="header">
                    {
                        modal.type === "filter-tags" ?
                            <button onClick={() => setFilterTags([])}>Temizle</button> :
                            null
                    }
                    <span >{types?.find((item) => item.type == modal.type)?.title}</span>
                    <button onClick={() => setModal({ status: false })}>{closeSvg}</button>
                </div>
                <div className="body">
                    {types?.find((item) => item.type === modal.type)?.body}
                </div>
            </div>
        </div>
    )
}

export default Modal;