import React, { useEffect, useState } from "react"
import CustomCard from "./CustomCard.js"
import Pagination from '@mui/material/Pagination'
import Box from '@mui/material/Box';
import { adminLeftDrawerStore, branchesStore, pendingStore, selectedBranchStore, selectedCategoryStore, selectedProductStore, selectedProductsStore } from "./store.js";
import { push, ref, set } from "firebase/database";
import { database, storage } from "./FirebaseConfig.js";
import CurrentBranchesDialog from "./CurrentBranchesDialog.js";
import { uploadBytes } from "firebase/storage";
import CategoryDrawer from "./CategoryDrawer.js";
import CustomCategoryCard from "./CustomCategoryCard.js";
import ProductDrawer from "./ProductDrawer.js";
import CustomProductCard from "./CustomProductCard.js";
import { isValidAndNotEmptyString, isValidArrayAndHasLength } from "./utils.js";
import "./BranchesComponent.css"
import { useSwipeable } from "react-swipeable";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import CustomFilterButton from "./Components/CustomFilterButton.js";
import { getItemStyle, getListStyle, reorder } from "./API.js";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';


const ProductComponent = () => {

    const selectedCategory = selectedCategoryStore((state) => state.selectedCategory)
    const selectedBranch = selectedBranchStore((state) => state.selectedBranch)
    const selectedProducts = selectedProductsStore((state) => state.selectedProducts)
    const setLeftDrawer = adminLeftDrawerStore((state) => state.setLeftDrawer)
    const leftDrawer = adminLeftDrawerStore((state) => state.leftDrawer)

    const [page, setPage] = useState(1)

    const [filter, setFilter] = useState(true)

    const [selectedProduct, setSelectedProduct] = useState(null)

    useEffect(() => {
        if (filter === false) {
            setPage(1)
        }
    }, [filter])

    const style = {
        boxStyle: {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            minHeight: (50 + 8) * 10,
        },
        boxStyleCol: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
        }
    }





    const onDragEnd = async (result) => {
        // dropped outside the list
        if (!result.destination) {
            console.warn("Drop outside the list", result)
            return;
        }
        let startIndex = result.source.index
        let endIndex = result.destination.index
        let itemId = result.draggableId
        if (isValidArrayAndHasLength(selectedProducts) && isValidArrayAndHasLength(selectedCategory)) {
            let a = selectedProducts.filter((item) => item[1].category === selectedCategory[0]).sort((a, b) => { return a[1]?.order - b[1]?.order })
            const items = await reorder(
                a,
                (10 * (page - 1)) + result.source.index,
                (10 * (page - 1)) + result.destination.index
            );

            await items.forEach(async (item, index) => {
                if (isValidAndNotEmptyString(item[0])) {
                    await set(ref(database, `products/${item[0]}/order`), index)
                }
            })
        }
    }

    return (

        <Box style={style.boxStyleCol}>
            <div className={"tableHead"}>
                <Breadcrumbs style={{marginBottom: 20}} aria-label="breadcrumb">
    
                    <Typography style={{textTransform: "capitalize"}} onClick={() => setLeftDrawer({ status: true, which: 0 })} underline="hover" color="inherit" >
                        {isValidArrayAndHasLength(selectedBranch) ? selectedBranch[1]?.name : null}
                    </Typography>
                    <Typography onClick={() => setLeftDrawer({ status: true, which: 1 })} underline="hover" color="inherit" >
                        {isValidArrayAndHasLength(selectedCategory) ? selectedCategory[1]?.name : null}
                    </Typography>
                    <Typography onClick={() => setLeftDrawer({ status: true, which: 2 })} underline="hover" color="inherit" >
                        Ürünler
                    </Typography>
                    
                </Breadcrumbs>
                <ProductDrawer />
            </div>
            <Box style={style.boxStyle}>


                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => {
                            return (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {
                                        isValidArrayAndHasLength(selectedProducts) && isValidArrayAndHasLength(selectedCategory) ?
                                            selectedProducts.filter((item) => item[1].category === selectedCategory[0]).sort((a, b) => { return a[1]?.order - b[1]?.order }).filter((item, i) => !filter || i < 10 * page && i >= 10 * (page - 1)).map((item, index) => (
                                                <Draggable key={item[0]} draggableId={item[0]} index={index}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    ...getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )
                                                                }}
                                                            >
                                                                <CustomProductCard key={item[0]} data={item} />
                                                            </div>
                                                        )
                                                    }}
                                                </Draggable>
                                            ))
                                            : null
                                    }
                                    {provided.placeholder}
                                </div>
                            )
                        }}
                    </Droppable>
                </DragDropContext>



            </Box>


            {
                isValidArrayAndHasLength(selectedProducts) ?
                    <div className="paginationRowWrapper">
                        <Pagination className="pagination" count={Math.ceil(filter ? selectedProducts.length / 10 : 1)} onChange={(e, val) => setPage(val)} page={page} variant="outlined" />
                        <CustomFilterButton style={{ width: 250, height: 35 }} set={setFilter} val={filter} labels={["Hepsi", "Tek Sayfa"]} />
                    </div>
                    : null
            }
        </Box>
    )
}

export default ProductComponent