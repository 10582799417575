import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const data = [
  {tr: 'Yeni', en: "New"},
  {tr: 'Popüler', en: "Popular"},
  {tr: 'Özel Sunum', en: "Signature Dish"},
  {tr: 'Taze', en: "Fresh"},
  {tr: 'Helal', en: "Halal"},
  {tr: 'Sağlıklı', en: "Healty"},
  {tr: 'Vegan', en: "Vegan"},
  {tr: 'Vejetaryen', en: "Vegetarian"},
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ChipSelect(props) {
  const {val, set} = props;

  const theme = useTheme();
  const [tags, setTags] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    set(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

  };
React.useEffect(() => {
  // console.log("tags", tags)
}, [val])
  return (
      <FormControl sx={{ width: "50%" }}>
        <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={val}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          defaultValue={['Özel Sunum']}
        >
          {data.map((item) => item.tr).map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={val?.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}