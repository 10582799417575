import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { auth, database, storage } from './FirebaseConfig';
import Skeleton from '@mui/material/Skeleton';
import { categoryDrawerStore, dialogStore, pendingStore, productsStore, selectedBranchStore, selectedCategoryStore, selectedProductsStore } from './store';
import { isValidAndNotEmptyString, isValidArrayAndHasLength } from './utils';
import "./CategoryCard.css"
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomDialog from './CustomDialog';
import { ref, remove, set } from 'firebase/database';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { useNavigate } from "react-router-dom"

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function CustomCategoryCard(props) {
    const { data, disabled } = props;

    const navigate = useNavigate()

    const selectedBranch = selectedBranchStore((state) => state.selectedBranch)
    const setSelectedBranch = selectedBranchStore((state) => state.setSelectedBranch)

    const setIsCategoryDrawerOpen = categoryDrawerStore((state) => state.setIsCategoryDrawerOpen)

    const selectedCategory = selectedCategoryStore((state) => state.selectedCategory)
    const setSelectedCategory = selectedCategoryStore((state) => state.setSelectedCategory)
    const products = productsStore((state) => state.products)

    
    const dialog = dialogStore((state) => state.dialog)
    const setDialog = dialogStore((state) => state.setDialog)

    const setPending = pendingStore((state) => state.setPending)

    const [imageError, setImageError] = React.useState(false);

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const aylar = [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık"
    ];

    let date = new Date(data[1].createdAt)
    // console.log("alooo", data[1]?.name, `${data[1]?.logo}`)


  
    const showConfirmationPopup = () => {
        return new Promise((resolve) => {
            setDialog({ status: true, title: "Dikkat", message: `"${selectedBranch[1].name}" şubenize ait "${data[1].name}" isimli kategorinizi silmek üzeresiniz.`, resolve: resolve })
        });
    };

    const handleDeleteCategory = async () => {
        const userConfirmed = await showConfirmationPopup();

        if (userConfirmed) {
            setPending(true)
            console.error("silindi", data[0])
            try {
                await remove(ref(database, `categories/${data[0]}/`))
            } catch (err) {
                console.error("handleDeleteCategory error: ", err)
            }
            setPending(false)
        }
    }


    console.log(products)


    return (
        <div className={"CategoryCardWrapper"}>
            <Card
                onClick={() => setSelectedCategory(data)}
                className={Array.isArray(selectedCategory) && selectedCategory[0] === data[0] ? "cardRow active" : "cardRow"}
             
                style={{
                    width: "100%",
                    height: 50,
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                sx={[
                    {
                        '&:hover': {
                            transition: ".2s",
                            backgroundColor: "#1976d240"
                        },
                    },

                ]}>
               
              <div className='row-first-col'>
              {
                  isValidArrayAndHasLength(data) && isValidAndNotEmptyString(data[1]?.logo) && !imageError
                        ? <img
                            src={data[1]?.logo}
                            onError={() => setImageError(true)}
                        />
                        : <Skeleton variant="rounded" width={150} height={150} />
                }
                <div className={"infoWrapper"}>
                    <h2>{data[1].name}</h2>
                    <h3>{aylar[date.getMonth()]} {date.getDate()}, {date.getFullYear()}</h3>
                    {/* <p>{data[1].description}</p> */}
                </div>
              </div>


                <div className='buttonsWrapper'>
              {isValidArrayAndHasLength(products) ? products.filter(item => item[1].category == data[0]).length : 0}

                    <IconButton  disabled={disabled} onClick={() => setIsCategoryDrawerOpen({ status: true, type: "update", data: data })} aria-label="delete">
                        <ModeEditIcon />
                    </IconButton>
                    <IconButton  disabled={disabled} onClick={() => navigate(`/${selectedBranch[1]?.name}/menu#${selectedCategory[0]}`)} aria-label="preview">
                        <VisibilityRoundedIcon />
                    </IconButton>
                    <IconButton  disabled={disabled} onClick={() => handleDeleteCategory()} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </div>

            </Card>
        </div>
    );
}