import { useEffect, useState } from "react";
import "./Feedback.css";
import Rating from '@mui/material/Rating';
import { isValidAndNotEmptyString, isValidArrayAndHasLength } from "../utils";
import { push, ref, set } from "firebase/database";
import { database } from "../FirebaseConfig";
import { useNavigate } from "react-router-dom"
import { t } from "i18next";
import { selectedBranchStore } from "../store";

const Feedback = () => {

    const navigate = useNavigate()

    const selectedBranch = selectedBranchStore(state => state.selectedBranch)


    const [yesNoFirst, setYesNoFirst] = useState(null)
    const [yesNoSecond, setYesNoSecond] = useState(null)


    const [textOne, setTextOne] = useState("");
    const [textTwo, setTextTwo] = useState("");
    const [textThird, setTextThird] = useState("");

    const [starOne, setStarOne] = useState(0);
    const [starTwo, setStarTwo] = useState(0);
    const [starThird, setStarThird] = useState(0);

    const [pending, setPending] = useState(false)

    const activeStar = <svg width="24" height="24" viewBox="0 0 24 24" fill="rgb(216, 43, 43)"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.186.902l1.978 6.365c.167.537.646.901 1.187.901h6.4c1.208 0 1.71 1.617.732 2.36l-5.177 3.935c-.437.332-.62.92-.453 1.458l1.977 6.365c.374 1.202-.941 2.202-1.92 1.459l-5.177-3.934a1.203 1.203 0 00-1.466 0l-5.178 3.934c-.978.743-2.293-.257-1.92-1.459l1.978-6.365a1.338 1.338 0 00-.453-1.458L.516 10.528c-.977-.743-.474-2.36.734-2.36h6.4c.54 0 1.019-.364 1.186-.901L10.814.902a1.231 1.231 0 012.372 0" fill="null"></path></svg>
    const pasiveStar = <svg width="24" height="24" viewBox="0 0 24 24" fill="rgb(216, 43, 43, .2)"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.186.902l1.978 6.365c.167.537.646.901 1.187.901h6.4c1.208 0 1.71 1.617.732 2.36l-5.177 3.935c-.437.332-.62.92-.453 1.458l1.977 6.365c.374 1.202-.941 2.202-1.92 1.459l-5.177-3.934a1.203 1.203 0 00-1.466 0l-5.178 3.934c-.978.743-2.293-.257-1.92-1.459l1.978-6.365a1.338 1.338 0 00-.453-1.458L.516 10.528c-.977-.743-.474-2.36.734-2.36h6.4c.54 0 1.019-.364 1.186-.901L10.814.902a1.231 1.231 0 012.372 0" fill="null"></path></svg>

    const [isFormOk, setIsFormOk] = useState(false)
    const [isSuccessFull, setSuccessFull] = useState(null)


    const handleIsFormOk = () => {
        if (
            isValidAndNotEmptyString(textOne) &&
            isValidAndNotEmptyString(textTwo) &&
            isValidAndNotEmptyString(textThird) &&
            yesNoFirst !== null &&
            yesNoSecond !== null
        ) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        setIsFormOk(handleIsFormOk())
    }, [textOne, textTwo, textThird, yesNoFirst, yesNoSecond])



    const sendForm = async () => {
        setPending(true)
        let date = new Date();
        try {
            if (!handleIsFormOk()) throw new Error("Form error")
            if (!isValidAndNotEmptyString(selectedBranch[0])) throw new Error("Branch error")

            await push(ref(database, `feedback/`), {
                yesNoFirst: yesNoFirst,
                yesNoSecond: yesNoSecond,
                textOne: textOne,
                textTwo: textTwo,
                textThird: textThird,
                starOne: starOne,
                starTwo: starTwo,
                starThird: starThird,
                branch: selectedBranch[0],
                time: date.getTime()
            })
        } catch (err) {
            console.error("send feedback error: ", err)
        }

        setSuccessFull(true)
        setPending(false)
    }


    useEffect(() => {
        if (isSuccessFull === true) {
            setTimeout(() => {
                window.history.go(-1)
            }, 2000)
        }
    }, [isSuccessFull])



    const goBackSvg = <svg width="16" height="16" viewBox="0 0 24 26" fill="#575962" _css="rotate(-180deg)" class="feedback___StyledIconArrow-sc-132na58-2 jMLfoS"><path d="M6.92 1c.255 0 .509.138.636.275l10.158 11.157c.381.413.381.964 0 1.377L7.683 24.69c-.381.413-.89.413-1.27 0-.381-.413-.381-.964 0-1.377l9.396-10.192L6.287 2.652c-.381-.413-.381-.964 0-1.378A.932.932 0 016.92 1z" fill="null" stroke="null" stroke-width="0.25"></path></svg>

    return (
        <div className={"FeedbackPageWrapper"}>
            <div className="header">
                <a onClick={() => window.history.go(-1)}>{goBackSvg}</a>
                <h2>{t("feedback.title")}</h2>
            </div>
            {
                !isSuccessFull ?
                    <>
                        <div className="yesnoWrapper">
                            <p>{t("feedback.q-1")}</p>
                            <div>
                                <button className={yesNoFirst === true ? "active" : ""} onClick={() => setYesNoFirst(true)}>{t("feedback.yes")}</button>
                                <button className={yesNoFirst === false ? "active" : ""} onClick={() => setYesNoFirst(false)}>{t("feedback.no")}</button>
                            </div>
                        </div>
                        <div className="giveStarWrapper">
                            <p>{t("feedback.q-2")}</p>
                            <Rating
                                name="simple-controlled"
                                value={starOne}
                                style={{ gap: 10 }}
                                onChange={(event, newValue) => {
                                    setStarOne(newValue);
                                }}
                                icon={activeStar}
                                emptyIcon={pasiveStar}
                            />
                        </div>
                        <div className="giveStarWrapper">
                            <p>{t("feedback.q-3")}</p>
                            <Rating
                                name="simple-controlled"
                                value={starTwo}
                                style={{ gap: 10 }}
                                onChange={(event, newValue) => {
                                    setStarTwo(newValue);
                                }}
                                icon={activeStar}
                                emptyIcon={pasiveStar}
                            />
                        </div>

                        <div className="giveStarWrapper">
                            <p>{t("feedback.q-4")}</p>
                            <Rating
                                name="simple-controlled"
                                value={starThird}
                                style={{ gap: 10 }}
                                onChange={(event, newValue) => {
                                    setStarThird(newValue);
                                }}
                                icon={activeStar}
                                emptyIcon={pasiveStar}
                            />
                        </div>
                        <div className="yesnoWrapper">
                            <p>{t("feedback.q-5")}</p>
                            <div>
                                <button className={yesNoSecond === true ? "active" : ""} onClick={() => setYesNoSecond(true)}>{t("feedback.yes")}</button>
                                <button className={yesNoSecond === false ? "active" : ""} onClick={() => setYesNoSecond(false)}>{t("feedback.no")}</button>
                            </div>
                        </div>



                        <div className="textAndInputWrapper">
                            <p>{t("feedback.q-6")}</p>
                            <input
                                value={textOne}
                                onChange={(e) => setTextOne(e.target.value)}
                            />
                        </div>
                        <div className="textAndInputWrapper">
                            <p>{t("feedback.q-7")}</p>
                            <input
                                value={textTwo}
                                onChange={(e) => setTextTwo(e.target.value)}
                            />
                        </div>
                        <div className="textAndInputWrapper">
                            <p>{t("feedback.q-8")}</p>
                            <input
                                value={textThird}
                                onChange={(e) => setTextThird(e.target.value)}
                            />
                        </div>

                        <button disabled={!isFormOk || pending} onClick={() => sendForm()} className={"submit"}>{pending ? t("feedback.pending") : t("feedback.submit")}</button>
                    </>
                    : <p className="thanksP">{t("feedback.thanks")}</p>
            }
        </div>
    )
}

export default Feedback;