import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';
import { isValidAndNotEmptyString } from './utils';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: "#000",
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten("#000", 0.85)
      : darken("#000", 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});


const top100Films = [];



export default function MultipleGroupedSelect(props) {
  const { label, dataArr, val, set } = props;

  const [options, setOptions] = React.useState([])
  const [current, setCurrent] = React.useState([])


  React.useEffect(() => {
    // console.log("val", val)

    let dummy = dataArr.filter((item) => !val?.includes(item.uid)).map((option) => {
      if(isValidAndNotEmptyString(option.name)) {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        }
      }
    })
    setOptions(dummy)

    setCurrent(dataArr.filter((item) => val?.includes(item.uid)))
  }, [val])

  const handleSet = (a) => {
    // console.log("a", a)
    set([...a.map((item) => item.uid)])
  }


  return (
    <Autocomplete
    key={val}
      multiple
      controll
      id="grouped-demo"
      options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.name}
      sx={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label={label} />}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      value={current}
      // defaultValue={options?.filter((item) => val?.includes(item.uid))}
      onChange={(e, v) => handleSet(v)}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top