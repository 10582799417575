import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { dialogStore } from './store';
import { Fragment, useEffect, useState } from 'react';
import "./CustomDialog.css";


const CustomDialog = () => {

    const dialog = dialogStore((state) => state.dialog)
    const setDialog = dialogStore((state) => state.setDialog)
    const updateDialog = dialogStore((state) => state.updateDialog)

    const [open, setOpen] = useState(dialog.status);

    useEffect(() => {
        setOpen(dialog.status)
        console.log(dialog)

    }, [dialog])


    const handleNo = () => {
        dialog.resolve(false)
        updateDialog({ status: false });
    };


    const handleYes = () => {
        dialog.resolve(true)
        updateDialog({ status: false });
    };

    return (
        <Fragment>

            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialog.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialog.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className='dialogNoButton' onClick={handleNo}>Vazgeç</button>
                    <button className='dialogYesButton' onClick={handleYes} autoFocus>
                        Devam Et
                    </button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default CustomDialog;